import React from 'react'
import {Table} from 'antd'
import {useQuery} from '@apollo/react-hooks'
import {diff} from 'json-diff'

import {MonthlyChangelogQ} from './queries'

interface ChangelogProps {
    entity: string,
    entityId: number
}

const safeJSONParse = value => {
  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

const subtractArrays = (arr1: Array<any>, arr2: Array<any>) =>
  arr1.filter(el => !arr2.includes(el))

const formatFromToValues = (oldValue, newValue): [any, any] => {
  let [prettyOldValue, prettyNewValue] = [safeJSONParse(oldValue), safeJSONParse(newValue)]

  if (Array.isArray(prettyOldValue) && Array.isArray(prettyNewValue)){
    if (prettyOldValue.length > prettyNewValue.length){
      return [
        "deleted",
        subtractArrays(prettyOldValue, prettyNewValue).join(",")
      ]
    } else {
      return [
        "added",
        subtractArrays(prettyNewValue, prettyOldValue).join(",")
      ]
    }
  } else if (typeof prettyOldValue === "object" && typeof prettyNewValue === "object"){
    return [
      diff(oldValue, newValue).__old,
      diff(oldValue, newValue).__new
    ]
  } else {
    return [prettyOldValue, prettyNewValue]
  }
}
const columns = [
  {key: 'editor', dataIndex: 'editor', title: 'Editor'},
  {key: 'field', dataIndex: 'field', title: 'Changed field'},
  {
    key: 'change',
    dataIndex: 'change',
    title: "From",
    render: ({old_value, new_value}) => <span>{String(formatFromToValues(old_value, new_value)[0])}</span>
  },
  {
    key: 'change',
    dataIndex: 'change',
    title: 'To',
    render: ({old_value, new_value}) => <span>{String(formatFromToValues(old_value, new_value)[1])}</span>
  },
  {key: 'date', dataIndex: 'date', title: 'At'}
]
export default ({entity, entityId}: ChangelogProps) => {
  const {error, data, loading} = useQuery(MonthlyChangelogQ, {variables: {entity, entityId}, fetchPolicy: 'no-cache'})

  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>

  return(
     <Table
       columns={columns}
       bordered
       dataSource={data.monthlyChangelog}
       rowKey='id'
     />
  )
}
