import React from 'react';
import {Layout} from 'antd'

import './main.css'

import Menu from "./menu"

const { Header, Content } = Layout

interface Props {
  children?: React.ReactNode
}

const App: React.FunctionComponent<Props> = ({children}) =>{
    return(
        <Layout>
          <Header>
            <Menu/>
          </Header>
         <Layout>
           <Layout style={{ padding: '24px', minHeight: 'calc(100vh - 65px)' }}>
             <Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
                {children}
             </Content>
         </Layout>
       </Layout>
      </Layout>
    )
}

export default App;
