import gql from "graphql-tag";

export const CreateVisaRequirementM = gql`
  mutation CreateVisaRequirement($input: CreateVisaRequirementInput!) {
    createVisaRequirement(input: $input) {
      id
    }
  }
`;

export const UpdateVisaRequirementM = gql`
  mutation UpdateVisaRequirement(
    $input: UpdateVisaRequirementInput!
    $id: Int!
  ) {
    updateVisaRequirement(input: $input, id: $id) {
      id
    }
  }
`;
