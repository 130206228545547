import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { Card, Button, Alert, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import equal from 'fast-deep-equal'

import { Coordinates, Translations } from '../../../types'
import { UpdateCityM } from './mutations'
import { CountriesGlossaryQ, CityQ } from './queries'
import TranslationsInput from '../../shared/translations'
import CoordinatesInput from '../../shared/inputs/coordinates'
import CountryInput from '../../shared/inputs/country'
import TimezoneInput from '../../shared/inputs/timezone'
import SlugsInput from '../../shared/inputs/slugs'
import TextInput from '../../shared/inputs/text'
import Changelog from '../../shared/changelog'

import '../../shared/form.css'
import { prepareComponents, prepareMutationInput, preprocessJSONInputsGently, processErrors } from '../../../utils'
import { getRule, getRules } from '../../../access_rules'

interface UpdateCityInput {
  coordinates?: Coordinates,
  countryId: number,
  iata: string,
  population?: number,
  seoTranslations?: Translations,
  slug?: string,
  slugRu?: string,
  timeZone: string,
  translations: Translations
}

export const schema = yup.object().shape({
  coordinates: yup.object().required().shape({
    lat: yup.number().required().test('lat', 'Coordinates: latitude must be set', val => val && val !== 0),
    lon: yup.number().required().test('lon', 'Coordinates: longitude must be set', val => val && val !== 0)
  }),
  countryId: yup.number().integer().positive('Country must be set').required(),
  iata: yup.string().required().length(3, 'IATA code must be exactly 3 symbols long').matches(/^[A-Z0-9]{3}$|^[А-Я0-9]{3}$/,
    'IATA must contain either A-Z letters and numbers or А-Я letters and numbers'),
  population: yup.number().required().min(0),
  seoTranslations: yup.object().shape({}).nullable(),
  slug: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug En: a-z, 0-9, -'),
  slugRu: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug Ru: a-z, 0-9, -'),
  timeZone: yup.string().required(),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation must be set') })
  }).required()
})

export default ({ match, initialInput, isDefaultInput }) => {
  const id = parseInt(match.params.id)
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateCityInput>(initialInput)

  const [updateCity, { data: mutationData }] = useMutation(UpdateCityM, setErrors)
  const { data: cityData, error, loading } = useQuery(CityQ, { variables: { id }, fetchPolicy: 'network-only' })

  if (error) return (<Alert type='error' message='City fetching error' />)
  if (loading) return (<Spin size='large' />)
  if (cityData.city === undefined) return (<></>)

  const { city } = cityData

  const serverDataInput = {
    iata: city.iata,
    population: parseInt(city.population),
    translations: city.translations,
    seoTranslations: city.seoTranslations,
    slug: city.slugEn,
    slugRu: city.slugRu,
    countryId: city.country.id,
    timeZone: city.timeZone?.name,
    coordinates: city.coordinates
  }

  if (isDefaultInput(input)) setInput(serverDataInput)

  if (mutationData) history.push({
    pathname: `/cities`,
    state: { mutationData: mutationData }
  })

  const performMutation = () => schema.validate(input)
    .then(_ => {
      const mutationInput = prepareMutationInput(serverDataInput, input);
      updateCity({ variables: { id, input: preprocessJSONInputsGently(mutationInput) } })
    }, e => processErrors(e, setErrors))

  const accessRules = getRules();

  const inputComponents = {
    translations: [
      {
        component: <TranslationsInput
          setTranslations={translations => setInput({ ...input, translations })}
          translations={input.translations}
        />,
        rule: getRule(accessRules, 'city', 'translations')
      }
    ],
    seoTranslations: [
      {
        component: <TranslationsInput
          setTranslations={seoTranslations => setInput({ ...input, seoTranslations })}
          translations={input.seoTranslations}
          hideFetchWikiData={true}
        />,
        rule: getRule(accessRules, 'city', 'seoTranslations')
      }
    ],
    changelog: [
      {
        component: <Changelog
          entityId={id}
          entity='CITY'
        />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ],
  }

  return (
    <>
      <Card
        tabList={[
          { key: 'main', tab: 'Main' },
          { key: 'translations', tab: 'Translations' },
          { key: 'seoTranslations', tab: 'SEO Translations' },
          { key: 'changelog', tab: 'Changelog' }
        ]}
        onTabChange={key => setActiveTab(key)}
        className='form'
      >
        {errors.map(e => <Alert type='error' message={e} key={e} />)}
        {
          {
            main: <MainForm
              setInput={setInput}
              input={input}
              accessRules={accessRules}
            />,
            translations: prepareComponents('translations', inputComponents.translations),
            seoTranslations: prepareComponents('seoTranslations', inputComponents.seoTranslations),
            changelog: prepareComponents('changelog', inputComponents.changelog),
          }[activeTab]
        }

        {
          (activeTab !== 'history') &&
          <>
            <Button
              style={{ float: 'left' }}
              disabled={equal(input, serverDataInput)}
              onClick={() => setInput(serverDataInput)}
              children='Reset Changes'
            />

            <Button
              onClick={performMutation}
              style={{ float: 'right' }}
              type='primary'
              children='Update city'
            />
          </>
        }
      </Card>
    </>
  )
}

const MainForm = (props: any) => {
  const { input, setInput, accessRules } = props
  const { data: inputsData, error, loading } = useQuery(CountriesGlossaryQ)

  if (error) return (<Alert type='error' message='Error fetching inputs data' />)
  if (loading) return (<Spin size='large' />)

  const inputComponents = [
    {
      component: <TextInput
        label='IATA'
        placeholder='IATA code, example: ZHK'
        value={input.iata}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-ZА-Я0-9]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, iata: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'city', 'iata')
    },
    {
      component: <CountryInput
        disabled={loading}
        onChange={countryId => setInput({ ...input, countryId })}
        value={input.countryId}
        countries={inputsData.countries}
      />,
      rule: getRule(accessRules, 'city', 'country')
    },
    {
      component: <TimezoneInput
        value={input.timeZone}
        disabled={loading}
        placeholder='City timezone'
        timeZones={inputsData.glossary?.timeZones}
        onChange={timeZone => setInput({ ...input, timeZone })}
      />,
      rule: getRule(accessRules, 'city', 'timeZone')
    },
    {
      component: <CoordinatesInput
        onChange={coordinates => setInput({ ...input, coordinates })}
        value={input.coordinates}
      />,
      rule: getRule(accessRules, 'city', 'coordinates')
    },
    {
      component: <TextInput
        value={input.population}
        onChange={e => setInput({ ...input, population: parseInt(e.target.value) || 0 })}
        label='Population'
      />,
      rule: getRule(accessRules, 'city', 'population')
    },
    {
      component: <SlugsInput
        slugRuVal={input.slugRu}
        slugVal={input.slug}
        onSlugRuChange={({ target: { value } }) => setInput({ ...input, slugRu: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        onSlugChange={({ target: { value } }) => setInput({ ...input, slug: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
      />,
      rule: getRule(accessRules, 'city', 'slug')
    }
  ]

  return (
    <>
      {
        prepareComponents('main-form', inputComponents)
      }
    </>
  )
}
