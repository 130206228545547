import React from 'react'
import {Switch, Route} from 'react-router-dom'

import List from './list'
import New from './new'
import Edit from './edit'

export default () => 
  <Switch>
      <Route path='/markets' exact component={List}/>
      <Route path='/markets/new' component={New}/>
      <Route path='/markets/:id' component={Edit}/>
  </Switch>
