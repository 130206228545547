import React from 'react'
import {Form, Select} from 'antd'
import LatestChange, {Data as LatestChangeData} from '../latest_change'

interface StringsInputProps {
  disabled?: boolean
  onChange?: (values: string[]) => void,
  values?: string[],
  label?: string
  latestChange?: LatestChangeData
}

export default ({disabled, onChange, label, values, latestChange}: StringsInputProps) => {
  return (
    <Form.Item label={label}>
      <Select disabled={disabled} mode="tags" onChange={onChange} value={values}>
        {values?.map(value => <Select.Option key={value} value={value}>{value}</Select.Option>)}
      </Select>
      <LatestChange data={latestChange} />
    </Form.Item>
  )
}
