import gql from 'graphql-tag'

export const LocalesQ = gql`
  query {
      locales {
          name 
          nameCases 
          hasNameCases
          language {
            translations(filter: { locales: ["en"] })
          }
      }
  }
`

export const MonthlyChangelogQ = gql`
  query GetMonthlyChangelog($entity: WatchedEntity!, $entityId: ID!){
    monthlyChangelog(entity: $entity, entityId: $entityId) { id editor change date field }
  }
`
