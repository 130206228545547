import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin } from 'antd'
import * as yup from 'yup'

import { CreateAirportM } from './mutations'
import { Translations, Coordinates } from '../../../types'
import { InputsDataQ } from './queries'

import TranslationsInput from '../../shared/translations'
import CityInput from '../../shared/inputs/city'
import TimezoneInput from '../../shared/inputs/timezone'
import StationTypeInput from '../../shared/inputs/station_type'
import CoordinatesInput from '../../shared/inputs/coordinates'
import SlugInputs from '../../shared/inputs/slugs'
import BoolInput from '../../shared/inputs/bool'
import TextInput from '../../shared/inputs/text'

import '../../shared/form.css'

import { processErrors, preprocessInputTranslations } from '../../../utils'

interface CreateAirportInput {
  cityId: number,
  coordinates: Coordinates,
  iata: string,
  iataType: string,
  isFlightable: boolean,
  seoTranslations?: Translations,
  slugRu: string,
  timeZone: string,
  translations: Translations
}

export const schema = yup.object().shape({
  cityId: yup.number().integer().positive('City must be set').required(),
  coordinates: yup.object().required().shape({
    lat: yup.number().required().test('lat', 'Coordinates: latitude must be set', val => val && val !== 0),
    lon: yup.number().required().test('lon', 'Coordinates: longitude must be set', val => val && val !== 0)
  }),
  iata: yup.string().length(3).required(),
  seoTranslations: yup.object().shape({}).nullable(),
  slugRu: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug Ru: a-z, 0-9, -'),
  timeZone: yup.string().required(),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation must be set') })
  }).required()
})

const defaultInput = {
  cityId: -1,
  coordinates: { lat: 0, lon: 0 },
  iata: '',
  iataType: 'airport',
  isFlightable: true,
  seoTranslations: {},
  slugRu: '',
  timeZone: '',
  translations: {}
}

export default () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<CreateAirportInput>(defaultInput)
  const [createAirport, { data: mutationData }] = useMutation(CreateAirportM, setErrors)

  const performMutation = () =>
    schema.validate(input)
      .then(_ => createAirport({ variables: { input: preprocessInputTranslations(input) } }),
        e => processErrors(e, setErrors))

  if (mutationData) history.push({
    pathname: `/airports`,
    state: { createdId: mutationData.createAirport.id }
  })

  return (
    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' },
        { key: 'seoTranslations', tab: 'SEO Translations' }
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
          />,
          translations: <TranslationsInput
            setTranslations={translations => setInput({ ...input, translations })}
            translations={input.translations}
          />,
          seoTranslations: <TranslationsInput
            translations={input.seoTranslations}
            setTranslations={seoTranslations => setInput({ ...input, seoTranslations })}
          />,
        }[activeTab]
      }

      <Button
        style={{ float: 'left' }}
        onClick={() => setInput(defaultInput)}
        children='Reset Changes'
      />

      <Button
        disabled={false}
        type='primary'
        htmlType='submit'
        style={{ float: 'right' }}
        children='Save'
        onClick={performMutation}
      />

    </Card>
  )
}
const MainForm = ({ input, setInput }) => {
  const { loading, error: qError, data: inputsData } = useQuery(InputsDataQ)

  if (loading) return (<Spin />)
  if (qError) return (<Alert message='Cities fetching error' />)

  return (
    <>
      <BoolInput
        label='is flightable'
        checked={input.isFlightable}
        onChange={val => setInput({ ...input, isFlightable: val })}
      />

      <TextInput
        label='IATA'
        value={input.iata}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-ZА-Я0-9]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, iata: validatedValue })
        }}
      />

      <CityInput
        disabled={loading}
        onChange={cityId => setInput({ ...input, cityId })}
        value={input.cityId}
        cities={inputsData.cities}
      />

      <StationTypeInput
        value={input.iataType || 'airport'}
        disabled={loading}
        placeholder='kind of station'
        onChange={iataType => setInput({ ...input, iataType })}
        stationTypes={inputsData.glossary.stationTypes}
      />

      <TimezoneInput
        value={input.timeZone}
        disabled={loading}
        placeholder='Airport timezone'
        onChange={timeZone => setInput({ ...input, timeZone })}
        timeZones={inputsData.glossary.timeZones}
      />

      <CoordinatesInput
        onChange={coordinates => setInput({ ...input, coordinates })}
        value={input.coordinates}
      />

      <SlugInputs
        slugRuVal={input.slugRu}
        slugVal={input.slug}
        onSlugRuChange={({ target: { value } }) => setInput({ ...input, slugRu: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        onSlugChange={({ target: { value } }) => setInput({ ...input, slug: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        disableSlugEn={true}
      />
    </>
  )
}
