import React from 'react'
import { Link } from 'react-router-dom'
import { MetropolisesQ } from './queries'
import IndexList from '../../shared/index_list'
import { Translations } from '../../../types'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../../access_rules'
import { prepareComponents } from '../../../utils'
import { Alert } from 'antd'

interface CutAirport { iata?: string, translations?: Translations }

interface ListMetropolisesResponse {
  id?: number,
  iata?: number,
  airports?: Array<CutAirport>
}

interface MetropolisesTableRow {
  id?: number,
  iata?: number,
  airports?: Array<CutAirport>
}

const responseToTable = (data: Array<ListMetropolisesResponse>): Array<MetropolisesTableRow> =>
  data.map(({ id, iata, airports }) => ({ id, iata, airports }))

export default (props: any) => {
  const accessRules = getRules()
  
  const createdMetropolyId = props.location?.state?.createdId

  const columns = [
    {
      title: "Iata",
      dataIndex: "iata",
      key: "iata",
      width: 100,
      hidden: notAllowedToShow(accessRules, 'metropoly', 'iata')
    },
    {
      title: "Airports",
      dataIndex: "airports",
      key: "airports",
      hidden: notAllowedToShow(accessRules, 'metropoly', 'airports'),
      render: (airports: Array<CutAirport>) =>
        <span>
          {
            airports.map(({ iata, translations }) => `${iata}(${translations?.en?.su})`)
              .join(', ')
          }
        </span>
    },
    {
      title: "Actions",
      dataIndex: "id",
      hidden: notAllowedToEdit(accessRules, 'metropoly'),
      render: (id: number) =>
        <Link to={`/metropolises/${id}`}>Edit</Link>
    }
  ]

  return (
    <>
      {createdMetropolyId && <Alert closable type='success' message={`Metropoly with id ${createdMetropolyId} created successfully`}></Alert>}
      {
        prepareComponents('metropolises-list', [
          {
            component: <IndexList
              columns={columns}
              request={{ query: MetropolisesQ, datakey: 'metropolis', transform: responseToTable }}
              title='Metropolises'
              rowKey='id'
              newEntityPath='/metropolises/new'
              showCreateButton={accessRules['__all_mutations']}
              size='small'
              filterFields={[{ key: 'iata', path: 'iata' }]}
            />,
            rule: getRule(accessRules, 'query', 'metropolises')
          }
        ])
      }
    </>
  )
}
