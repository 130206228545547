import gql from 'graphql-tag'

export const CreateMarketM  = gql`
  mutation CreateMarket($input: CreateMarketInput!){
      createMarket(input: $input){ id }
  }
`

export const UpdateMarketM = gql`
  mutation UpdateMarket($input: UpdateMarketInput!, $id: Int!){
    updateMarket(input: $input, id: $id){ id }
  }
`
