import gql from 'graphql-tag'

export const MetropolisesQ = gql`
  query {
    metropolis {
      id 
      iata 

      airports {
        iata 
        translations
      }
    }
  }
`

export const MetropolisQ = gql`
  query getMetropolis($id: Int!){
    metropoly(id: $id){
      id 
      iata 
      
      airports {id iata translations}
    }
  }
`

export const AllAirportsQ = gql`
  query {
    airports { id iata translations }
  }
`
