import gql from 'graphql-tag'

export const CreateLanguageM = gql`
  mutation CreateLanguage($input: CreateLanguageInput!){
    createLanguage(input: $input) { id }
  }
`

export const UpdateLanguageM = gql`
  mutation UpdateLanguage($input: UpdateLanguageInput!, $id: Int!){
    updateLanguage(input: $input, id: $id) { id code }
  }
`
