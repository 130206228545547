import React, { useState } from 'react'
import { useMutation } from '../../mutation'
import { useQuery } from '../../query'
import * as yup from 'yup'
import { Card, Button, Alert, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import equal from 'fast-deep-equal'

import { UpdateLocationM } from './mutations'
import { LocationQ, InputsLocationTypeQ } from './queries'
import { Translations } from '../../types'

import LocationTypeInput from './inputs/location_type'
import TranslationsInput from '../shared/translations'
import BoolInput from '../shared/inputs/bool'
import TextInput from '../shared/inputs/text'

import Changelog from '../shared/changelog'

import { processErrors, prepareComponents, prepareMutationInput, preprocessJSONInputsGently } from '../../utils'
import { getRule, getRules } from '../../access_rules'

interface UpdateLocationInput {
  isActive?: boolean,
  type?: string,
  slug?: string,
  translations?: Translations,
  iata?: string
}

export const schema = yup.object().shape({
  iata: yup.string().matches(/^[A-Z]{2,3}|[А-Я]{3}$/, {
    message: 'iata must be 2 or 3 characters long and contain only A-Z or А-Я letters', excludeEmptyString: true
  }).nullable(),
  isActive: yup.boolean(),
  slug: yup.string(),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation must be set') })
  }),
  type: yup.string()
})

export default (props) => {
  const id = props.match.params.id
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateLocationInput>({})
  const [updateLocation, { data: mutationData }] = useMutation(UpdateLocationM, setErrors)
  const { data: locationData, error, loading } = useQuery(LocationQ, { variables: { id }, fetchPolicy: 'network-only' })

  if (error) return (<Alert type='error' message='Location fetching error' />)
  if (loading) return (<Spin size='large' />)

  const { location: { isActive, type, slug, translations, iata } } = locationData
  const defaultInput = { isActive, type, slug, translations, iata }

  if (Object.keys(input).length === 0) setInput(defaultInput)

  const mutationInput = prepareMutationInput(defaultInput, input);
  const performMutation = () =>
    schema.validate(input)
      .then(_ => updateLocation({ variables: { input: preprocessJSONInputsGently(mutationInput), id } }),
        e => processErrors(e, setErrors))

  if (mutationData) history.push(`/locations`)

  const accessRules = getRules()
  const components = {
    translations: [
      {
        component: <TranslationsInput
          setTranslations={translations => setInput({ ...input, translations })}
          translations={input.translations}
        />,
        rule: getRule(accessRules, 'location', 'translations')
      }
    ],
    changelog: [
      {
        component: <Changelog
          entityId={id}
          entity='LOCATION'
        />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ]
  }

  return (
    <>
      <Card
        tabList={[
          { key: 'main', tab: 'Main' },
          { key: 'translations', tab: 'Translations' },
          { key: 'changelog', tab: 'Changelog' }
        ]}
        onTabChange={key => setActiveTab(key)}
        className='form'
      >

        {errors.map(e => <Alert type='error' message={e} key={e} />)}

        {
          {
            main: <MainForm
              setInput={setInput}
              input={input}
              accessRules={accessRules}
            />,
            translations: prepareComponents('translations', components.translations),
            changelog: prepareComponents('changelog', components.changelog),
          }[activeTab]
        }

        {
          (activeTab !== 'changelog') &&
          <>
            <Button
              style={{ float: 'left' }}
              disabled={equal(input, defaultInput)}
              onClick={() => setInput(defaultInput)}
              children='Reset Changes'
            />

            <Button
              onClick={performMutation}
              style={{ float: 'right' }}
              type='primary'
              children='Update location'
            />
          </>
        }
      </Card>
    </>
  )
}

const MainForm = ({ input, setInput, accessRules }) => {
  const { data: inputsData, error, loading } = useQuery(InputsLocationTypeQ)

  if (error) return (<Alert type='error' message='Error fetching location type data' />)
  if (loading) return (<Spin size='large' />)

  const inputComponents = [
    {
      component: <BoolInput
        checked={input.isActive}
        onChange={val => setInput({ ...input, isActive: val })}
        label='is active'
      />,
      rule: getRule(accessRules, 'location', 'isActive')
    },
    {
      component: <LocationTypeInput
        value={input.type || 'CITY'}
        disabled={loading}
        placeholder='location type'
        onChange={type => setInput({ ...input, type })}
        locationTypes={inputsData.__type.enumValues}
      />,
      rule: getRule(accessRules, 'location', 'type')
    },
    {
      component: <TextInput
        label='slug'
        placeholder='slug'
        value={input.slug}
        onChange={e => setInput({ ...input, slug: e.target.value })}
      />,
      rule: getRule(accessRules, 'location', 'slug')
    },
    {
      component: <TextInput
        label='iata'
        value={input.iata}
        placeholder='iata (3 characters, A-Z/А-Я)'
        onChange={e => setInput({ ...input, iata: e.target.value })}
      />,
      rule: getRule(accessRules, 'location', 'iata')
    }
  ]

  return (
    <>
      {
        prepareComponents('main-form', inputComponents)
      }
    </>
  )
}
