import React, { useState } from 'react'
import {Form, Select} from 'antd'

interface LanguageInputProps {
    languages: Array<{id: number, code: string}>,
    value: number, 
    disabled: boolean, 
    onChange: (_) => void
}

export default ({onChange, disabled, value, languages}: LanguageInputProps) => {
  const [searchVal, setSearchVal] = useState('')

  return (
    <Form.Item label='Language'>
      <Select
        disabled={disabled}
        value={value}
        onChange={onChange}
        onSearch={val => setSearchVal(val)}
        filterOption={false}
        showSearch={true}
      >
        <Select.Option value={-1} key='null'>
            Not selected
        </Select.Option>

      {
        languages
        .filter(l => l.code.includes(searchVal))
        .map(({id, code}) => 
            <Select.Option value={id} key={`language-${id}`}>
                {code}
            </Select.Option>
      )}
      </Select>
  </Form.Item>

  )
}
  