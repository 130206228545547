import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin } from 'antd'

import * as yup from 'yup'
import { CreateCountryM } from './mutations'
import { Translations } from '../../../types'
import { CapitalsQ, CitizenshipCountriesQ } from './queries'

import TranslationsInput from '../../shared/translations'
import CapitalInput from '../../shared/inputs/city'
import SlugsInput from '../../shared/inputs/slugs'
import TextInput from '../../shared/inputs/text'
import CountryInput from '../../shared/inputs/country'
import BoolInput from '../../shared/inputs/bool'

import '../../shared/form.css'
import { preprocessInputTranslations, processErrors } from '../../../utils'

interface CreateCountryInput {
  capitalId: number,
  citizenshipCountryId: number,
  currency: string,
  gdprRequired: boolean,
  iata: string,
  population: number,
  seoTranslations?: Translations,
  slugRu: string,
  translations: Translations
}

export const schema = yup.object().shape({
  capitalId: yup.number().nullable().positive('Capital of the country must be set').required(),
  citizenshipCountryId: yup.number().positive('Citizenship country must be set').required(),
  currency: yup.string().length(3, 'Currency must be exactly 3 symbols long').required().matches(/^[A-Z]{3}$/),
  gdprRequired: yup.boolean().required(),
  iata: yup.string().required().length(2, 'IATA code must be exactly 2 symbols long').matches(/^[A-Z]{2}$/,
    'IATA must contain only A-Z letters'),
  population: yup.number().min(0).required(),
  seoTranslations: yup.object().shape({}).nullable(),
  slugRu: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug Ru: a-z, 0-9, -'),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation must be set') })
  }).required()
})

const defaultInput = {
  capitalId: -1,
  citizenshipCountryId: -1,
  currency: '',
  gdprRequired: false,
  iata: '',
  population: 0,
  seoTranslations: {},
  slugRu: '',
  translations: {}
}

export default () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<CreateCountryInput>(defaultInput)
  const [createCountry, { data: mutationData }] = useMutation(CreateCountryM, setErrors)

  if (mutationData) history.push({
    pathname: `/countries`,
    state: { createdId: mutationData.createCountry.id }
  })

  const performMutation = () =>
    schema.validate(input)
      .then(_ => createCountry({ variables: { input: preprocessInputTranslations(input) } }),
        e => processErrors(e, setErrors))

  return (
    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' },
        { key: 'seoTranslations', tab: 'SEO Translations' }
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
          />,
          translations: <TranslationsInput
            setTranslations={translations => setInput({ ...input, translations })}
            translations={input.translations}
          />,
          seoTranslations: <TranslationsInput
            translations={input.seoTranslations}
            setTranslations={seoTranslations => setInput({ ...input, seoTranslations })}
          />,
        }[activeTab]
      }

      <Button
        style={{ float: 'left' }}
        onClick={() => setInput(defaultInput)}
        children='Reset Changes'
      />

      <Button
        disabled={false}
        type='primary'
        htmlType='submit'
        style={{ float: 'right' }}
        children='Save'
        onClick={performMutation}
      />
    </Card>
  )
}
const MainForm = (props: any) => {
  const { setInput, input } = props;
  const { loading, error: qError, data: inputsData } = useQuery(CapitalsQ)
  const { data: countriesData, error: countriesError, loading: countriesLoading } = useQuery(CitizenshipCountriesQ);

  if (loading || countriesLoading) return (<Spin />)
  if (countriesError) return (<Alert message='Countries fetching error' />)
  if (qError) return (<Alert message='Cities fetching error' />)

  return (
    <>
      <TextInput
        label='IATA'
        placeholder='AA'
        value={input.iata}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-Z]/i, '').toUpperCase()
          if (validatedValue.length <= 2) return setInput({ ...input, iata: validatedValue })
        }}
      />

      <CapitalInput
        label='Capital'
        cities={inputsData.cities}
        disabled={loading}
        onChange={capitalId => setInput({ ...input, capitalId })}
        value={input.capitalId}
      />

      <TextInput
        label='Population'
        value={input.population}
        onChange={e => setInput({ ...input, population: parseInt(e.target.value) || 0 })}
      />

      <TextInput
        label='Currency'
        placeholder='USD'
        value={input.currency}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-Z]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, currency: validatedValue })
        }}
      />

      <SlugsInput
        slugVal={input.slug}
        slugRuVal={input.slugRu}
        onSlugRuChange={({ target: { value } }) => setInput({ ...input, slugRu: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        onSlugChange={({ target: { value } }) => setInput({ ...input, slug: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        disableSlugEn={true}
      />

      <CountryInput
        label='Citizenship country'
        countries={countriesData.countries}
        disabled={countriesLoading}
        value={input.citizenshipCountryId}
        onChange={citizenshipCountryId => setInput({ ...input, citizenshipCountryId })}
      />

      <BoolInput
        label='GDRP required'
        checked={input.gdprRequired}
        onChange={val => setInput({ ...input, gdprRequired: val })}
      />
    </>
  )
}
