import React from 'react'
import { Form } from 'antd'
import BoolInput from './inputs/bool'
import TimeInput from './inputs/time'
import moment from 'moment'

interface TransitZoneData {
  hasTransitZone: boolean | null
  transitWorkHoursMin: number | null
  transitWorkHoursMax: number | null
}

interface TransitZoneProps extends TransitZoneData {
  onChange: (v: Partial<TransitZoneData>) => void
}

function minutesToDate(val: number | null): Date | null {
  if (val === null) {
    return val
  }
  const h = Math.round(val / 60)
  const m = val - (h * 60)
  return moment(`${h}:${m}`, 'H:m').toDate()
}

function momentToMinutes(val: moment.Moment | null) {
  if (!val) {
    return val
  }
  return val.minutes() + (val.hours() * 60)
}

export default function TransitZoneInput(props: TransitZoneProps): any {
  return (
    <div>
      <Form.Item>
        <BoolInput
          label="Has transit zone"
          checked={Boolean(props.hasTransitZone)}
          onChange={hasTransitZone => {
            props.onChange({ hasTransitZone })
          }}
        />
        {
          props.hasTransitZone
            ? (
              <React.Fragment>
                <TimeInput 
                  label="Work hours start"
                  value={props.transitWorkHoursMin ? minutesToDate(props.transitWorkHoursMin) : null}
                  onChange={(data) => {
                    props.onChange({ transitWorkHoursMin: momentToMinutes(data) })
                  }}
                />
                <TimeInput 
                  label="Work hours end"
                  value={props.transitWorkHoursMax ? minutesToDate(props.transitWorkHoursMax) : null}
                  onChange={(data) => {
                    props.onChange({ transitWorkHoursMax: momentToMinutes(data) })
                  }}
                />
              </React.Fragment>
            )
            : null
        }
      </Form.Item>
    </div>
  )
}
