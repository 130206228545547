import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { CreateLocaleM } from './mutations'
import { LanguagesQ } from './queries'
import { useHistory } from 'react-router-dom'
import { Button, Spin, Card, Alert } from 'antd'

import * as yup from 'yup'
import LanguageInput from '../../shared/inputs/language'
import BoolInput from '../../shared/inputs/bool'
import TextInput from '../../shared/inputs/text'

import '../../shared/form.css'

import { processErrors } from '../../../utils'

interface CreateLocaleInput {
  languageId: number,
  hasNameCases?: boolean,
  nameCases?: string, // split on ['a', 'b'] at the very end
  name: string
}

export const schema = yup.object().shape({
  name: yup.string().required(),
  languageId: yup.number().positive().required()
})

const formatInputNameCases = (input: CreateLocaleInput): object =>
  ({ ...input, nameCases: input.nameCases?.split(',') })

const defaultInput = { languageId: -1, hasNameCases: false, nameCases: '', name: '' }

export default () => {
  let history = useHistory()

  const { loading, error: qError, data: inputsData } = useQuery(LanguagesQ)
  const [input, setInput] = useState<CreateLocaleInput>(defaultInput)
  const [errors, setErrors] = useState<Array<string>>([])
  const [createLocale, { data: mutationData }] = useMutation(CreateLocaleM, setErrors)

  if (loading) return (<Spin />)
  if (qError) return (<Alert type='error' message='Fetching error' />)

  if (mutationData) history.push({
    pathname: `/locales`,
    state: { createdId: mutationData.createLocale.id }
  })

  const performMutation = () =>
    schema.validate(input)
      .then(_ => createLocale({ variables: { input: formatInputNameCases(input) } }),
        e => processErrors(e, setErrors))

  return (
    <Card
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} />)}

      <TextInput
        label='Name'
        value={input.name || null}
        onChange={e => setInput({ ...input, name: e.target.value })}
      />

      <BoolInput
        label='Has name cases?'
        checked={!!input.hasNameCases}
        onChange={hasNameCases => setInput({ ...input, hasNameCases })}
      />

      <TextInput
        label='Name cases'
        value={input.nameCases || null}
        onChange={e => setInput({ ...input, nameCases: e.target.value })}
      />

      <LanguageInput
        disabled={loading}
        value={input.languageId || -1}
        onChange={languageId => setInput({ ...input, languageId })}
        languages={inputsData.languages}
      />

      <Button
        style={{ float: 'left' }}
        onClick={() => setInput(defaultInput)}
        children='Reset Changes'
      />

      <Button
        disabled={false}
        type='primary'
        htmlType='submit'
        style={{ float: 'right' }}
        children='Save'
        onClick={performMutation}
      />
    </Card>
  )
}
