import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher, Observable } from 'apollo-boost'
import { GRAPHQL_URL } from './constants'
import { getJWT, updateJWT } from 'pios-client'

const addJWTToOperationHeaders = (operation, token) => {
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  })
}

export default new ApolloClient({
  uri: GRAPHQL_URL,
  request: (operation) => {
    const token = getJWT()
    addJWTToOperationHeaders(operation, token)
  },
  /*
  Apollo is fucking great #2
  https://github.com/apollographql/apollo-client/issues/3397#issuecomment-421433032
  */
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: {
        __schema: {
          types: []
        }
      }
    })
  }),
  /*
  Apollo is fucking great
  What's going on here
  GitHub issue: https://github.com/apollographql/apollo-link/issues/646
  Solution example on StackOverFlow: https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
  
  We catch 'Unauthorized' error and retry with a new token
  */
  onError: ({ networkError, operation, forward }) => {
    if (networkError) {
      return new Observable(observer =>
        updateJWT()
          .then(token => addJWTToOperationHeaders(operation, token))
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            }

            forward(operation).subscribe(subscriber)
          })
          .catch(error => observer.error(error))
      )
    }
  }
})
