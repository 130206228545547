import React from 'react'
import { Switch, Route } from 'react-router-dom'
import List from './list'
import New from './new'
import Edit from './edit'
import { isDefaultInput } from '../../form_defaults'

export default () =>
  <Switch>
    <Route path='/airlines' exact component={List} />
    <Route path='/airlines/new' component={New} />
    <Route
      path='/airlines/:id'
      render={props => <Edit
        initialInput={""}
        isDefaultInput={input => isDefaultInput(input)}
        {...props}
      />}
    />
  </Switch>
