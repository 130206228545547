import React from 'react'
import {Form, TimePicker} from 'antd'
import moment from 'moment'
import LatestChange, {Data as LatestChangeData} from '../latest_change'

interface DateInputProps {
  onChange: (time: moment.Moment | null, timeString: string) => void,
  disabled?: boolean,
  value: string | Date | null,
  label: string
  latestChange?: LatestChangeData
}

export default ({ onChange, disabled, value, label, latestChange }: DateInputProps) =>
  <Form.Item label={label}>
    <TimePicker
      disabled={disabled}
      onChange={(time, timeString) => {
        onChange(time, timeString)
      }}
      format="HH:mm"
      defaultValue={!value ? undefined : moment(value, 'HH:mm')}
    />
    <LatestChange data={latestChange} />
  </Form.Item>