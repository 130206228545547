import gql from 'graphql-tag'

export const CreateCityM = gql`
  mutation CreateCity($input: CreateCityInput!) {
    createCity(input: $input) { id }
  }
`

export const UpdateCityM = gql`
  mutation UpdateCity($input: UpdateCityInput!, $id: Int!){
    updateCity(input: $input, id: $id) { id iata }
  }
`
