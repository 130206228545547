import React from 'react'
import { Alert,Switch } from 'antd'
import { Link } from 'react-router-dom'
import { ListLocalesQ } from './queries'
import { Locale } from '../../../types'
import IndexList from '../../shared/index_list'
import { prepareComponents } from '../../../utils'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../../access_rules'

interface ListLocalesTableRow {
  name: string,
  hasCases: boolean,
  cases: string[]
}

const responseToTable = (data: Array<Locale>): Array<ListLocalesTableRow> =>
  data.map(({ id, name, hasNameCases, nameCases }) => ({ id, name, hasCases: hasNameCases, cases: nameCases }))

export default (props: any) => {
  const accessRules = getRules()

  const createdLocaleId = props.location?.state?.createdId

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: 100,
      hidden: notAllowedToShow(accessRules, 'locale', 'name')
    },
    {
      title: "Cases?",
      key: "hasCases",
      width: 100,
      dataIndex: "hasCases",
      hidden: notAllowedToShow(accessRules, 'locale', 'hasCases'),
      render: (hasCases: boolean) =>
        <div style={{ textAlign: 'center' }}>
          <Switch
            size='small'
            defaultChecked={hasCases}
            disabled={true}
          />
        </div>
    },
    {
      title: "Name Cases",
      key: "cases",
      dataIndex: "cases",
      hidden: notAllowedToShow(accessRules, 'locale', 'nameCases')
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: 60,
      render: (id: number) =>
        <span><Link to={`/locales/${id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'locale')
    }
  ]

  return (
    <>
      {createdLocaleId && <Alert closable type='success' message={`Locale with id ${createdLocaleId} created successfully`}></Alert>}
      {
        prepareComponents('locales-list', [{
          component: <IndexList
            request={{ query: ListLocalesQ, datakey: 'locales', transform: responseToTable }}
            title='Locales'
            newEntityPath='/locales/new'
            showCreateButton={accessRules['__all_mutations']}
            columns={columns}
            rowKey='id'
          />,
          rule: getRule(accessRules, 'query', 'locales')
        }])
      }
    </>
  )
}
