import gql from 'graphql-tag'

export const ListLocalesQ = gql`
  query {
      locales {
          id
          name
          hasNameCases
          nameCases
      }
  }
`

export const LanguagesQ = gql`
  query {
    languages {
      id
      code
    }
  }
`

export const LocaleQ = gql`
  query GetLocale($id: Int!){
    locale(id: $id) {
      name
      hasNameCases
      nameCases

      language { id code }
    }
  }
`
