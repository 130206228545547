import { Tag } from "antd"
import React from "react"

interface ActiveBadgeProps {
    isActive: boolean
    activeText: string
    inactiveText: string
}


export default (props:  ActiveBadgeProps): any =>{
    return (
        <Tag color={props.isActive ? "green" : "red"}>
            {props.isActive ? props.activeText :  props.inactiveText}
        </Tag>
    )
}
