import gql from "graphql-tag";

export const ListCountriesQ = gql`
  query {
    countries {
      id
      iata
      iso
      translations(filter: { locales: ["en", "ru"] })

      capital {
        id
        iata
        translations(filter: { locales: ["en", "ru"] })
      }
    }
  }
`;

export const CapitalsQ = gql`
  query {
    cities {
      id
      iata
      translations(filter: { locales: ["en", "ru"] })
    }
  }
`;

export const CitizenshipCountriesQ = gql`
  query {
    countries {
      id
      iata
      translations(filter: { locales: ["en", "ru"] })
    }
  }
`;

export const CountryQ = gql`
  query GetCountry($id: Int!) {
    country(id: $id) {
      id
      iata
      iso
      currency
      population
      slugRu: slug(marketCode: "ru")
      slugEn: slug(marketCode: "us")
      translations
      seoTranslations
      gdprRequired

      capital {
        id
        translations(filter: { locales: ["en", "ru"] })
      }

      citizenshipCountry {
        id
        translations(filter: { locales: ["en", "ru"] })
      }
    }
  }
`;

export const ContentTextsQ = gql`
  query GetCountryContentTexts($id: Int!, $market: String!){
    contentPages(marketCode: $market) {
      countryPage(countryId: $id){
        visaContent(market: $market)
        climateContent(market: $market)
        timezoneContent(market: $market)
        moneyContent(market: $market)
        rentContent(market: $market)
      }
    }
  }
`;
