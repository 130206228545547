import { useMutation as apolloUseMutation } from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import { processErrors } from './utils'

export function useMutation(
    mutation: DocumentNode,
    setErrors: React.Dispatch<React.SetStateAction<string[]>>
) {
    return apolloUseMutation(mutation, { onError: (obj) => { processErrors(obj, setErrors) } })
}
