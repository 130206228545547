import React from 'react'
import {Tooltip, Typography} from 'antd'
const {Text} = Typography

export interface Data {
  editor: string,
  change: {
    old_value: string,
    new_value: string
  },
  date: string,
  field: string
}

interface LatestChangeProps {
  data?: Data
}

export default ({data}: LatestChangeProps) => {
  return (
    <>
      {data &&
      <Tooltip title={`${data.date} by ${data.editor}`}>
        <Text type="secondary" style={{float: 'right'}}>Last edited</Text>
      </Tooltip>}
    </>
  )
}
