import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin } from 'antd'
import equal from 'fast-deep-equal'
import * as yup from 'yup'

import { CreateCityM } from './mutations'
import { Translations, Coordinates } from '../../../types'
import { CountriesGlossaryQ } from './queries'

import TranslationsInput from '../../shared/translations'
import CountryInput from '../../shared/inputs/country'
import SlugsInput from '../../shared/inputs/slugs'
import CoordinatesInput from '../../shared/inputs/coordinates'
import TimezoneInput from '../../shared/inputs/timezone'
import TextInput from '../../shared/inputs/text'

import '../../shared/form.css'
import { preprocessInputTranslations, processErrors } from '../../../utils'

interface CreateCityInput {
  coordinates: Coordinates,
  countryId: number,
  iata: string,
  population: number,
  seoTranslations?: Translations,
  slugRu: string,
  timeZone: string,
  translations: Translations
}

export const schema = yup.object().shape({
  coordinates: yup.object().required().shape({
    lat: yup.number().required().test('lat', 'Coordinates: latitude must be set', val => val && val !== 0),
    lon: yup.number().required().test('lon', 'Coordinates: longitude must be set', val => val && val !== 0)
  }),
  countryId: yup.number().integer().positive('Country must be set').required(),
  iata: yup.string().required().length(3, 'IATA code must be exactly 3 symbols long').matches(/^[A-Z0-9]{3}$|^[А-Я0-9]{3}$/,
    'IATA must contain either A-Z letters and numbers or А-Я letters and numbers'),
  population: yup.number().required().min(0),
  seoTranslations: yup.object().shape({}).nullable(),
  slugRu: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug Ru: a-z, 0-9, -'),
  timeZone: yup.string().required(),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation must be set') })
  }).required()
})

const defaultInput = {
  coordinates: { lat: 0, lon: 0 },
  countryId: -1,
  iata: '',
  population: 0,
  seoTranslations: {},
  slugRu: '',
  timeZone: '',
  translations: {}
}

export default () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<CreateCityInput>(defaultInput)
  const [createCity, { data: mutationData, loading }] = useMutation(CreateCityM, setErrors)

  if (mutationData) history.push({
    pathname: `/cities`,
    state: { createdId: mutationData.createCity.id }
  })

  const performMutation = () =>
    schema.validate(input)
      .then(_ => createCity({ variables: { input: preprocessInputTranslations(input) } }),
        e => processErrors(e, setErrors))

  return (

    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' },
        { key: 'seoTranslations', tab: 'SEO Translations' }
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
      loading={loading}
    >
      {errors.map(e => <Alert type='error' message={e} />)}

      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
          />,
          translations: <TranslationsInput
            setTranslations={translations => setInput({ ...input, translations })}
            translations={input.translations}
          />,
          seoTranslations: <TranslationsInput
            translations={input.seoTranslations}
            setTranslations={seoTranslations => setInput({ ...input, seoTranslations })}
          />,
        }[activeTab]
      }

      <>
        <Button
          style={{ float: 'left' }}
          disabled={equal(input, defaultInput)}
          onClick={() => setInput(defaultInput)}
          children='Reset Changes'
        />

        <Button
          onClick={performMutation}
          style={{ float: 'right' }}
          type='primary'
          children='Save'
        />
      </>

    </Card>
  )
}
const MainForm = ({ input, setInput }) => {
  const { loading, error, data: inputsData } = useQuery(CountriesGlossaryQ)

  if (error) return (<Alert message='Inputs data fetching error' type='error' />)
  if (loading) return (<Spin size='large' />)
  return (
    <>
      <TextInput
        label='IATA'
        value={input.iata}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-ZА-Я0-9]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, iata: validatedValue })
        }}
      />

      <CountryInput
        disabled={loading}
        value={input.countryId}
        countries={inputsData.countries}
        onChange={countryId => setInput({ ...input, countryId })}
      />

      <TimezoneInput
        disabled={loading}
        placeholder='City timezone'
        value={input.timeZone}
        timeZones={inputsData.glossary.timeZones}
        onChange={timeZone => setInput({ ...input, timeZone })}
      />

      <CoordinatesInput
        onChange={coordinates => setInput({ ...input, coordinates })}
        value={input.coordinates}
      />

      <TextInput
        value={input.population}
        onChange={({ target: { value } }) => {
          let validatedValue = parseInt(value)
          if (isNaN(validatedValue)) return setInput({ ...input, population: 0 })
          return setInput({ ...input, population: validatedValue })
        }}
        label='Population'
      />

      <SlugsInput
        slugRuVal={input.slugRu}
        slugVal={input.slug}
        onSlugRuChange={({ target: { value } }) => setInput({ ...input, slugRu: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        onSlugChange={({ target: { value } }) => setInput({ ...input, slug: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        disableSlugEn={true}
      />
    </>
  )
}
