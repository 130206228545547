import React from 'react'
import { Switch, Route } from 'react-router-dom'
import List from './list'
import Edit from './edit'
import New from './new'
import { isDefaultInput } from '../../../form_defaults'

export default () =>
  <Switch>
    <Route path='/countries' exact component={List} />
    <Route path='/countries/new' component={New} />

    <Route path='/countries/:id' render={props =>
      <Edit
        initialInput={""}
        isDefaultInput={input => isDefaultInput(input)}
        {...props}
      />
    } />
  </Switch>
