import React, { useMemo } from 'react'
import { Form, Select } from 'antd'
import { Translations } from '../../../types';
import { translationsContainString } from '../../../utils'
import LatestChange, { Data as LatestChangeData } from '../latest_change'

interface CountrySelectProps {
  label?: string,
  formItemDisabled?: boolean,
  onChange: (id: any) => void,
  value?: number,
  disabled: boolean,
  countries: Array<{ id: number, iata: string, translations: Translations }>,
  multi?: boolean,
  latestChange?: LatestChangeData
}

interface SelectOption {
  label: string;
  value: number;
  iata?: string;
  translations?: Translations;
}

const filterOption = (searchStr: string, option: SelectOption | undefined) => {
  if (!option) return false;
  const { translations, iata } = option;
  const lowercaseSearch = searchStr.toLowerCase();

  const isTranslationsMatch = translations
    ? translationsContainString(translations, lowercaseSearch)
    : false;
  const isIataMatch = iata?.toLowerCase()?.includes(lowercaseSearch);
  return isTranslationsMatch || isIataMatch;
};


export default ({ label, formItemDisabled, disabled, countries, value, onChange, multi, latestChange }: CountrySelectProps) => {
  const options = useMemo(
    () =>
      countries.map(({ id, translations, iata }) => ({
        label: translations?.en?.su,
        value: id,
        translations,
        iata,
      })).concat([{ label: 'Click to select...', value: -1, translations: {}, iata: '' }]),
    [countries],
  );

  const select = (
    <Select
      mode={multi ? "multiple" : undefined}
      disabled={disabled}
      style={{ width: '80%', alignSelf: 'center' }}
      value={value}
      onChange={onChange}
      options={options}
      filterOption={filterOption as any}
      showSearch={true}
    >
    </Select>
  )

  if (formItemDisabled)
    return select
  else
    return <Form.Item label={label ? label : 'Country'}>
      {select}
      <LatestChange data={latestChange} />
    </Form.Item>
}


