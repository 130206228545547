import React from 'react'
import { Form, Input } from 'antd'

interface SlugsInputProps {
  slugVal: string,
  slugRuVal: string,
  onSlugChange: (_) => void,
  onSlugRuChange: (_) => void,
  disabled?: boolean,
  disableSlugEn?: boolean
}

export default ({ slugVal, slugRuVal, onSlugChange, onSlugRuChange, disabled, disableSlugEn }: SlugsInputProps) =>
  <>
    <Form.Item label='Slug Ru'>
      <Input
        required
        value={slugRuVal}
        onChange={onSlugRuChange}
        placeholder='Slug for the Russian market. Example: nazvaniye-abc'
        disabled={disabled || false}
      />

    </Form.Item>

    <Form.Item label='Slug En'>
      <Input
        required
        value={slugVal}
        onChange={onSlugChange}
        placeholder='Slug for other markets. Example: title-abc'
        disabled={disabled || disableSlugEn || false}
      />
    </Form.Item>
  </>
