import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import * as yup from 'yup'
import { Form, Card, Button, Alert, Spin } from 'antd'
import { Coordinates, Translations } from '../../../types'
import { useHistory } from 'react-router-dom'
import equal from 'fast-deep-equal'

import { UpdateAirportM } from './mutations'
import { InputsDataQ, AirportQ } from './queries'
import TranslationsInput from '../../shared/translations'
import CityInput from '../../shared/inputs/city'
import TimezoneInput from '../../shared/inputs/timezone'
import SlugsInput from '../../shared/inputs/slugs'
import CoordinatesInput from '../../shared/inputs/coordinates'
import StationTypeInput from '../../shared/inputs/station_type'
import BoolInput from '../../shared/inputs/bool'
import TextInput from '../../shared/inputs/text'
import TransitZoneInput from '../../shared/transit_zone'

import Changelog from '../../shared/changelog'
import { processErrors, prepareComponents, prepareMutationInput, preprocessJSONInputsGently } from '../../../utils'
import { getRules, getRule } from '../../../access_rules'

interface UpdateAirportInput {
  cityId: number,
  coordinates: Coordinates,
  hasTransitZone?: boolean,
  iata: string,
  iataType: string,
  isFlightable: boolean,
  metropolyId?: number,
  seoTranslations?: Translations,
  slug: string,
  slugRu: string,
  timeZone: string,
  translations: Translations
}

export const schema = yup.object().shape({
  cityId: yup.number().integer().positive('City must be set').required(),
  coordinates: yup.object().required().shape({
    lat: yup.number().required().test('lat', 'Coordinates: latitude must be set', val => val && val !== 0),
    lon: yup.number().required().test('lon', 'Coordinates: longitude must be set', val => val && val !== 0)
  }),
  hasTransitZone: yup.boolean().nullable(),
  iata: yup.string().required().length(3, 'IATA code must be exactly 3 symbols long').matches(/^[A-Z0-9]{3}$|^[А-Я0-9]{3}$/,
    'IATA must contain either A-Z letters and numbers or А-Я letters and numbers'),
  seoTranslations: yup.object().shape({}).nullable(),
  slug: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug En: a-z, 0-9, -'),
  slugRu: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug Ru: a-z, 0-9, -'),
  timeZone: yup.string().required(),
  transitWorkHoursMax: yup.mixed().when("hasTransitZone", {
    is: true,
    then: yup.number().integer('Must enter work hours end').min(0).required("Must enter work hours end"),
  }),
  transitWorkHoursMin: yup.mixed().when("hasTransitZone", {
    is: true,
    then: yup.number().integer('Must enter work hours start').min(0).required("Must enter work hours start"),
    otherwise: null
  }),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation must be set') })
  }).required()
})

export default ({ match, initialInput, isDefaultInput }) => {
  const id = parseInt(match.params.id)
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateAirportInput>(initialInput)
  const [updateAirport, { data: mutationData }] = useMutation(UpdateAirportM, setErrors)
  const { data: airportData, error, loading } = useQuery(AirportQ, { variables: { id }, fetchPolicy: 'network-only' })

  if (error) return (<Alert type='error' message='Airport fetching error' />)
  if (loading) return (<Spin size='large' />)

  const { airport: { timeZone, metropoly, isFlightable, seoTranslations, translations, coordinates, city, iata, slugRu, slugEn, type, hasTransitZone, transitWorkHoursMin, transitWorkHoursMax } } = airportData

  const serverDataInput = {
    cityId: city?.id,
    coordinates,
    hasTransitZone,
    iata,
    iataType: type,
    isFlightable,
    metropolyId: metropoly?.id,
    seoTranslations: seoTranslations,
    slug: slugEn,
    slugRu,
    timeZone: timeZone,
    transitWorkHoursMax,
    transitWorkHoursMin,
    translations: translations
  }

  if (isDefaultInput(input)) setInput(serverDataInput)

  if (mutationData) history.push({
    pathname: '/airports',
    state: { mutationData: mutationData }
  })

  const performMutation = () =>
    schema.validate(input)
      .then(_ => {
        const mutationInput = prepareMutationInput(serverDataInput, input)
        updateAirport({ variables: { id, input: preprocessJSONInputsGently(mutationInput) as UpdateAirportInput } })
      }, e => processErrors(e, setErrors))

  const accessRules = getRules();

  const inputComponents = {
    translations: [
      {
        component: <TranslationsInput
          setTranslations={translations => setInput({ ...input, translations })}
          translations={input.translations}
        />,
        rule: getRule(accessRules, 'airport', 'translations')
      }
    ],
    seoTranslations: [
      {
        component: <TranslationsInput
          setTranslations={seoTranslations => setInput({ ...input, seoTranslations })}
          translations={input.seoTranslations}
          hideFetchWikiData={true}
        />,
        rule: getRule(accessRules, 'airport', 'seoTranslations')
      }
    ],
    changelog: [
      {
        component: <Changelog
          entity='AIRPORT'
          entityId={id}
        />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ],
  }

  return (
    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' },
        { key: 'seoTranslations', tab: 'SEO Translations' },
        { key: 'changelog', tab: 'Changelog' }
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}
      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
            accessRules={accessRules}
          />,
          translations: prepareComponents('translations', inputComponents.translations),
          seoTranslations: prepareComponents('seoTranslations', inputComponents.seoTranslations),
          changelog: prepareComponents('changelog', inputComponents.changelog),
        }[activeTab]
      }

      {
        (activeTab !== 'changelog') &&
        <>
          <Button
            style={{ float: 'left' }}
            disabled={equal(input, serverDataInput)}
            onClick={() => setInput(serverDataInput)}
            children='Reset Changes'
          />

          <Button
            onClick={performMutation}
            style={{ float: 'right' }}
            type='primary'
            children='Update airport'
          />
        </>
      }
    </Card>
  )
}

const MainForm = ({ input, setInput, accessRules }: any) => {
  const { data: inputsData, error, loading } = useQuery(InputsDataQ)

  if (error) return (<Alert message='Inputs data fetching error' type='error' />)
  if (loading) return (<Spin size='large' />)

  const inputComponents = [
    {
      component: <BoolInput
        label='is flightable'
        checked={input.isFlightable}
        onChange={isFlightable => setInput({ ...input, isFlightable })}
      />,
      rule: getRule(accessRules, 'airport', 'isFlightable')
    },
    {
      component: <TextInput
        label='IATA'
        placeholder='IATA code, example: ZHK'
        value={input.iata}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-ZА-Я0-9]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, iata: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'airport', 'iata')
    },
    {
      component: <CityInput
        disabled={loading}
        value={input.cityId}
        onChange={cityId => setInput({ ...input, cityId })}
        cities={inputsData.cities}
      />,
      rule: getRule(accessRules, 'airport', 'city')
    },
    {
      component: <StationTypeInput
        value={input.iataType || 'airport'}
        disabled={loading}
        onChange={iataType => setInput({ ...input, iataType })}
        stationTypes={inputsData.glossary?.stationTypes}
      />,
      rule: getRule(accessRules, 'airport', 'iataType')
    },
    {
      component: <TimezoneInput
        timeZones={inputsData.glossary?.timeZones || []}
        value={input.timeZone}
        disabled={loading}
        placeholder='Airport timezone'
        onChange={timeZone => setInput({ ...input, timeZone })}
      />,
      rule: getRule(accessRules, 'airport', 'timeZone')
    },
    {
      component: <CoordinatesInput
        onChange={(coordinates) => setInput({ ...input, coordinates })}
        value={input.coordinates}
      />,
      rule: getRule(accessRules, 'airport', 'coordinates')
    },
    {
      component: <SlugsInput
        onSlugRuChange={({ target: { value } }) => setInput({ ...input, slugRu: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        onSlugChange={({ target: { value } }) => setInput({ ...input, slug: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        slugVal={input.slug}
        slugRuVal={input.slugRu}
      />,
      rule: getRule(accessRules, 'airport', 'slug')
    },
    {
      component: <TransitZoneInput
        onChange={v => {
          setInput({ ...input, ...v })
        }}
        hasTransitZone={input.hasTransitZone}
        transitWorkHoursMin={input.transitWorkHoursMin}
        transitWorkHoursMax={input.transitWorkHoursMax}
      />,
      rule: getRule(accessRules, 'airport', 'hasTransitZone')
    }
  ]

  return (
    <Form>
      {
        prepareComponents('main-form', inputComponents)
      }
    </Form>
  )
}
