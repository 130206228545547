import gql from 'graphql-tag'

export const CreateAirportM = gql`
  mutation CreateAirport($input: CreateAirportInput!) {
    createAirport(input: $input){ id }
  }
`

export const UpdateAirportM = gql`
  mutation UpdateAirport($input: UpdateAirportInput!, $id: Int!){
    updateAirport(input: $input, id: $id){ id iata }
  }
`
