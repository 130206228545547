import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { CreateMetropolisM } from './mutations'
import { Alert, Card, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import TextInput from '../../shared/inputs/text'

import { processErrors } from '../../../utils'

interface CreateMetropolisInput {
  iata?: string
}

const schema = yup.object().shape({
  iata: yup.string().required().test('len', 'iata length must be == 3', val => val && val.length === 3)
})

export default () => {
  const history = useHistory()
  const [input, setInput] = useState<CreateMetropolisInput>({})
  const [errors, setErrors] = useState<Array<string>>([])

  const [createMetropolis, { data }] = useMutation(CreateMetropolisM, setErrors)

  const performMutation = () => {
    schema.validate(input)
      .then(_ => createMetropolis({ variables: {input: input} }),
        e => processErrors(e, setErrors))
  }

  if (data) history.push({
    pathname: `/metropolises`,
    state: { createdId: data.createMetropoly.id }
  })

  return (
    <Card
      style={{ marginLeft: '25%', marginRight: '25%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}
    >

      {errors.map(e => <Alert style={{ marginBottom: '5%' }} type='error' key={e} message={e} />)}

      <h4 style={{ paddingBottom: '3%', marginLeft: '20%' }}>Create metropoly</h4>
      <TextInput
        label='Iata'
        value={input.iata}
        onChange={e => setInput({ ...input, iata: e.target.value })}
      />

      <Button
        type='primary'
        style={{ marginLeft: '30%' }}
        onClick={performMutation}
        children='Save'
      />
    </Card>
  )
}
