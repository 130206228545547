import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin } from 'antd'
import * as yup from 'yup'
import equal from 'fast-deep-equal'
import { UpdateCountryM } from './mutations'
import { Translations } from '../../../types'
import { CapitalsQ, CitizenshipCountriesQ, CountryQ } from './queries'
import TranslationsInput from '../../shared/translations'
import SlugsInput from '../../shared/inputs/slugs'
import CapitalInput from '../../shared/inputs/city'
import TextInput from '../../shared/inputs/text'
import BoolInput from '../../shared/inputs/bool'
import CountryInput from '../../shared/inputs/country'
import CountryContentTexts from './content_texts'
import Changelog from '../../shared/changelog'

import '../../shared/form.css'
import { processErrors, preprocessJSONInputsGently, prepareMutationInput, prepareComponents } from '../../../utils'
import { getRules, getRule } from '../../../access_rules'

interface UpdateCountryInput {
  capitalId?: number,
  citizenshipCountryId: number,
  currency?: string,
  gdprRequired: boolean,
  iata: string,
  iso?: string,
  population: number,
  seoTranslations?: Translations,
  slug: string
  slugRu: string,
  translations: Translations
}

export const schema = yup.object().shape({
  capitalId: yup.number().nullable().positive(),
  citizenshipCountryId: yup.number().positive('Citizenship country must be set').required(),
  currency: yup.string().length(3, 'Currency must be exactly 3 symbols long').required().matches(/^[A-Z]{3}$/),
  gdprRequired: yup.boolean().required(),
  iata: yup.string().required().length(2, 'IATA code must be exactly 2 symbols long').matches(/^[A-Z]{2}$/,
    'IATA must contain only A-Z letters'),
  population: yup.number().min(0).required(),
  seoTranslations: yup.object().shape({}).nullable(),
  slug: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug En: a-z, 0-9, -'),
  slugRu: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug Ru: a-z, 0-9, -'),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation must be set') })
  }).required()
})

export default ({ match, initialInput, isDefaultInput }) => {
  const id = parseInt(match.params.id)

  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateCountryInput>(initialInput)

  const [updateCountry, { data: mutationData }] = useMutation(UpdateCountryM, setErrors)
  const { data: countryData, error: countryLoadingError, loading: countryLoading } = useQuery(CountryQ, { variables: { id }, fetchPolicy: 'network-only' })

  if (countryLoadingError) return (<Alert type='error' message='Country fetching error' />)
  if (countryLoading) return (<Spin size='large' />)
  if (countryData.country === undefined) return (<></>)

  const { country } = countryData

  const serverDataInput = {
    iata: country.iata,
    iso: country.iso,
    currency: country.currency,
    translations: country.translations,
    seoTranslations: country.seoTranslations,
    slugRu: country.slugRu,
    slug: country.slugEn,
    capitalId: country.capital?.id,
    population: country.population,
    citizenshipCountryId: country.citizenshipCountry?.id,
    gdprRequired: country.gdprRequired,
  }

  if (isDefaultInput(input)) setInput(serverDataInput)

  if (mutationData) history.push({
    pathname: `/countries`,
    state: { mutationData: mutationData }
  })

  const performMutation = () => schema.validate(input)
    .then(_ => {
      const mutationInput = prepareMutationInput(serverDataInput, input);
      updateCountry({ variables: { input: preprocessJSONInputsGently(mutationInput), id } })
    }, e => processErrors(e, setErrors))

  const accessRules = getRules();

  const inputComponents = {
    translations: [
      {
        component: <TranslationsInput
          setTranslations={translations => setInput({ ...input, translations })}
          translations={input.translations}
        />,
        rule: getRule(accessRules, 'country', 'translations')
      }
    ],
    seoTranslations: [
      {
        component: <TranslationsInput
          setTranslations={seoTranslations => setInput({ ...input, seoTranslations })}
          translations={input.seoTranslations}
          hideFetchWikiData={true}
        />,
        rule: getRule(accessRules, 'country', 'seoTranslations')
      }
    ],
    contentTexts: [
      {
        component: <CountryContentTexts
          countryId={id}
        />,
        rule: getRule(accessRules, 'countryPage', 'visaContent')
      }
    ],
    changelog: [
      {
        component: <Changelog
          entity='COUNTRY'
          entityId={id}
        />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ]
  }

  return (
    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' },
        { key: 'seoTranslations', tab: 'SEO Translations' },
        { key: 'contentTexts', tab: 'Content Texts' },
        { key: 'changelog', tab: 'Changelog' }
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        {
          main: <MainForm
            input={input}
            setInput={setInput}
            accessRules={accessRules}
          />,
          translations: prepareComponents('translations', inputComponents.translations),
          seoTranslations: prepareComponents('seoTranslations', inputComponents.seoTranslations),
          contentTexts: prepareComponents('contentTexts', inputComponents.contentTexts),
          changelog: prepareComponents('changelog', inputComponents.changelog)
        }[activeTab]
      }

      <Button
        style={{ float: 'left' }}
        disabled={equal(input, serverDataInput)}
        onClick={() => setInput(serverDataInput)}
        children='Reset Changes'
        hidden={activeTab === 'contentTexts'}
      />
      <Button
        onClick={performMutation}
        style={{ float: 'right' }}
        type='primary'
        children='Update country'
        hidden={activeTab === 'contentTexts'}
      />
    </Card>
  )
}

const MainForm = (props: any) => {
  const { input, setInput, accessRules } = props;

  const { data: capitalsData, error: capitalsError, loading: capitalsLoading } = useQuery(CapitalsQ)
  const { data: countriesData, error: countriesError, loading: countriesLoading } = useQuery(CitizenshipCountriesQ);

  if (capitalsError) return (<Alert type='error' message='Error fetching inputs data' />)
  if (countriesError) return (<Alert message='Countries fetching error' />)
  if (capitalsLoading || countriesLoading) return (<Spin size='large' />)

  const inputComponents = [
    {
      component: <TextInput
        label='IATA'
        value={input.iata}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-Z]/i, '').toUpperCase()
          if (validatedValue.length <= 2) return setInput({ ...input, iata: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'country', 'iata')
    },
    {
      component: <TextInput
        label='ISO'
        value={input.iso}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-Z]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, iso: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'country', 'iso')
    },
    {
      component: <CapitalInput
        label='Capital'
        disabled={false}
        value={input.capitalId}
        onChange={capitalId => setInput({ ...input, capitalId })}
        cities={capitalsData.cities}
      />,
      rule: getRule(accessRules, 'country', 'capital')
    },
    {
      component: <TextInput
        label='Currency'
        value={input.currency}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-Z]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, currency: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'currency', 'iata')
    },
    {
      component: <TextInput
        label='Population'
        value={input.population}
        onChange={e => setInput({ ...input, population: parseInt(e.target.value) || 0 })}
      />,
      rule: getRule(accessRules, 'population', 'iata')
    },
    {
      component: <SlugsInput
        slugRuVal={input.slugRu}
        slugVal={input.slug}
        onSlugRuChange={({ target: { value } }) => setInput({ ...input, slugRu: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        onSlugChange={({ target: { value } }) => setInput({ ...input, slug: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
      />,
      rule: getRule(accessRules, 'slug', 'iata')
    },
    {
      component: <CountryInput
        label='Citizenship country'
        countries={countriesData.countries}
        disabled={countriesLoading}
        value={input.citizenshipCountryId}
        onChange={citizenshipCountryId => setInput({ ...input, citizenshipCountryId })}
      />,
      rule: getRule(accessRules, 'country', 'citizenshipCountryId')
    },
    {
      component: <BoolInput
        label='GDRP required'
        checked={input.gdprRequired}
        onChange={val => setInput({ ...input, gdprRequired: val })}
      />,
      rule: getRule(accessRules, 'country', 'gdprRequired')
    }
  ]

  return (
    <>
      {
        prepareComponents('main-form', inputComponents)
      }
    </>
  )
}
