import React, { useState } from 'react'
import { Form, Card, Input, Alert, Spin, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { Translations } from '../../../types'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import * as yup from 'yup'
import equal from 'fast-deep-equal'

import { UpdateLanguageM } from './mutations'
import { LanguageQ } from './queries'

import Changelog from '../../shared/changelog'
import TranslationsInput from '../../shared/translations'

import { processErrors, prepareComponents, prepareMutationInput, preprocessJSONInputsGently } from '../../../utils'
import { getRule, getRules } from '../../../access_rules'

interface UpdateLanguageInput {
  code?: string,
  translations?: Translations
}

const schema = yup.object().shape({
  code: yup.string().required().length(2),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required() }).required()
  })
})

export default (props) => {
  const id = parseInt(props.match.params.id)
  const history = useHistory()
  const [activeTab, setActiveTAb] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateLanguageInput>({})

  const [updateLanguage, { data: mutationData }] = useMutation(UpdateLanguageM, setErrors)
  const { data: langData, error, loading } = useQuery(LanguageQ, { variables: { id }, fetchPolicy: 'network-only' })

  if (error) return (<Alert type='error' message='Language fetching error' />)
  if (loading) return (<Spin size='large' />)
  if (langData.language === undefined) return (<></>)

  const { language: { code, translations } } = langData

  const defaultInput = { code, translations }

  if (Object.keys(input).length === 0) setInput(defaultInput)

  if (mutationData) history.push({
    pathname: `/languages`,
    state: { mutationData: mutationData }
  })

  const performMutation = () =>
    schema.validate(input)
      .then(_ => {
        const mutationInput = prepareMutationInput(defaultInput, input);
        updateLanguage({ variables: { input: preprocessJSONInputsGently(mutationInput), id } })
      }, e => processErrors(e, setErrors))

  const accessRules = getRules()

  const components = {
    translations: [
      {
        component: <TranslationsInput
          setTranslations={translations => setInput({ ...input, translations })}
          translations={input.translations} />,
        rule: getRule(accessRules, 'language', 'translations')
      },
    ],
    changelog: [
      {
        component: <Changelog entity='LANGUAGE' entityId={id} />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ]
  }

  return (
    <>
      <Card
        tabList={[
          { key: 'main', tab: 'Main' },
          { key: 'translations', tab: 'Translations' },
          { key: 'changelog', tab: 'Changelog' }
        ]}
        onTabChange={key => setActiveTAb(key)}
        className='form'
      >
        {errors.map(e => <Alert type='error' message={e} key={e} />)}

        {
          {
            main: <MainForm setInput={setInput} input={input} accessRules={accessRules} />,
            translations: prepareComponents('translations', components.translations),
            changelog: prepareComponents('changelog', components.changelog)
          }[activeTab]
        }

        {
          (activeTab !== 'changelog') &&
          <>
            <Button
              style={{ float: 'left' }}
              disabled={equal(input, defaultInput)}
              onClick={() => setInput(defaultInput)}
              children='Reset Changes'
            />

            <Button
              onClick={performMutation}
              style={{ float: 'right' }}
              type='primary'
              children='Update language'
            />
          </>
        }
      </Card>
    </>
  )
}

const MainForm = ({ setInput, input, accessRules }) =>
  <>
    {
      prepareComponents('main-form', [{
        component: <Form.Item>
          <Input
            value={input.code}
            onChange={e => setInput({ ...input, code: e.target.value })}
          />
        </Form.Item>,
        rule: getRule(accessRules, 'language', 'code')
      }])
    }
  </>
