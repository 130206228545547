import React, { useState } from 'react'
import { Space, Form, Input, Alert, Spin, Button, Divider } from 'antd'
import { useQuery } from '../../query'
import { LocalesQ } from './queries'
import './translations.css'
import { Translations } from '../../types'
import { processErrors } from '../../utils'

type TranslationsInputProps = {
  setTranslations: (value: Translations) => void,
  translations?: any,
  disabled?: boolean,
  multiline?: boolean,
  localesOrder?: Array<string>,
  hideFetchWikiData?: boolean
}

type WikiDataTranslations = Array<{
  site: string,
  title: string
}>

export default (props: TranslationsInputProps): any => {
  const { loading, error, data: inputsData } = useQuery(LocalesQ)
  const [errors, setErrors] = useState<Array<string>>([])
  const [sortedLocales, setSortedLocales] = useState([])
  const [fetchedWikidataTranslations, setFetchedWikidataTranslations] = useState<boolean>(false)
  const [wikiDataURL, setWikiDataURL] = useState('')

  if (error) return (<Alert type='error' message='Fetching locales error' />)
  if (loading) return (<Spin size='large' />)

  if (inputsData.locales.length > 0 && sortedLocales.length === 0)
    setSortedLocales(inputsData.locales
      .sort(
        (a: { name: string }, b: { name: string }) => {
          const aName = a.name
          const bName = b.name

          if (props.localesOrder) {
            const aIdx = props.localesOrder.indexOf(aName)
            const bIdx = props.localesOrder.indexOf(bName)

            if (aIdx > -1 && (bIdx === -1 || aIdx < bIdx)) {
              return -1
            } else if (bIdx > -1 && (aIdx === -1 || aIdx > bIdx)) {
              return 1
            }
          }

          const aTranslations = props.translations?.[aName]
          const bTranslations = props.translations?.[bName]

          const hasATranslations = Object.values(aTranslations || {}).filter(tr => tr !== 'null').length > 0
          const hasBTranslations = Object.values(bTranslations || {}).filter(tr => tr !== 'null').length > 0

          if (aTranslations && (!bTranslations || (hasATranslations && !hasBTranslations))) {
            return -1
          } else if (bTranslations && (!aTranslations || (!hasATranslations && hasBTranslations))) {
            return 1
          }

          if (aName && (!bName || aName < bName)) {
            return -1
          } else if (bName && (!aName || aName > bName)) {
            return 1
          }

          return 0
        }
      ))

  const updateTranslations = (localeName: string, nameCase: string, value: string) =>
    props.setTranslations(
      {
        ...props.translations,
        [localeName]: {
          ...(props.translations ? props.translations[localeName] : {}),
          [nameCase]: value
        }
      }
    )

  const fetchTranslationsFromWikiData = () => {
    setErrors([])
    setFetchedWikidataTranslations(false)

    const urlError = 'Wikidata URL should be in https://www.wikidata.org/wiki/{identifier} format.'
    const fetchError = 'Unable to fetch translations from Wikidata.'
    const identifier = wikiDataURL.match(/^\s*https?:\/\/www.wikidata.org\/wiki\/(.+)\s*$/)?.[1]

    if (typeof (identifier) === 'string') {
      fetch(`https://www.wikidata.org/wiki/Special:EntityData/${identifier}.json`)
        .then((response) => response.json()).then((data) => {
          const wikiDataTranslations: WikiDataTranslations = Object.values(data['entities'][identifier]['sitelinks'])

          const result: Translations = wikiDataTranslations.reduce((acc, { site, title }) => {
            const isoLanguage = site.replace('wiki', '')
            const locales = inputsData.locales.map(({ name }) => name).filter((localeName: string) =>
              localeName.replace(/-.*/, '') === isoLanguage)

            locales.forEach((locale: any) => Object.assign(acc, { [locale]: { 'su': title } }))

            return acc
          }, {})

          props.setTranslations(Object.assign(result, props.translations))
          setFetchedWikidataTranslations(true)
        },
          () => processErrors({ errors: [fetchError] }, setErrors))
    }
    else processErrors({ errors: [urlError] }, setErrors)
  }

  return (
    <div className='input-translations'>
      {errors.map(e => <Alert type='error' message={e} key={e} />)}
      {fetchedWikidataTranslations && <Alert type='success' message='Translations successfully fetched' />}
      <Space direction='horizontal' style={{ paddingTop: 5, visibility: props.hideFetchWikiData ? 'hidden' : 'visible' }} >
        <Input
          style={{ width: 430, float: 'left' }}
          placeholder='https://www.wikidata.org/wiki/12345'
          value={wikiDataURL}
          onChange={e => setWikiDataURL(e.target.value)}
        />

        <Button type='primary' onClick={fetchTranslationsFromWikiData} style={{ float: 'right' }}>
          Fetch Wikidata translations
        </Button>
      </Space>
      {!props.hideFetchWikiData && <Divider />}
      {
        sortedLocales.map(({ name, hasNameCases, nameCases, language: { translations: { en: { su: languageName } } } }) =>
          <Form.Item
            key={`translations-${name}`}
            label={languageName ? `${name} (${languageName})` : name}
          >
            {
              ['su', ...(hasNameCases && !props.multiline ? nameCases || [] : [])].map((nameCase) => {
                if (props.multiline) {
                  return (
                    <Input.TextArea
                      key={`translations-${name}-${nameCase}`}
                      name={`translations-${name}`}
                      value={props.translations?.[name]?.[nameCase]}
                      disabled={props.disabled || false}
                      onChange={e => updateTranslations(name, nameCase, e.target.value)}
                    />
                  )
                } else {
                  return (
                    <Input
                      className='input-translations__input'
                      key={`translations-${name}-${nameCase}`}
                      name={`translations-${name}`}
                      value={props.translations?.[name]?.[nameCase]}
                      disabled={props.disabled || false}
                      addonBefore={hasNameCases ? nameCase : null}
                      onChange={e => updateTranslations(name, nameCase, e.target.value)}
                    />
                  )
                }
              })
            }
          </Form.Item>
        )
      }
    </div >
  )
}
