import React from 'react'
import {Switch, Route} from 'react-router-dom'

import List from './list'
import New from './new'
import Edit from './edit'


export default () => 
  <Switch>
      <Route path='/locations' exact component={List}/>
      <Route path='/locations/new' component={New}/>
      <Route path='/locations/:id' component={Edit}/>
  </Switch>
