import React, { useState } from 'react'
import { useMutation } from '../../mutation'
import { useQuery } from '../../query'
import { Card, Button, Alert, Spin } from 'antd'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import equal from 'fast-deep-equal'

import { UpdateAirlineM } from './mutations'
import { AirlineQ, CountriesQ } from './queries'
import { Translations, Website } from '../../types'

import Changelog from '../shared/changelog'

import TranslationsInput from '../shared/translations'
import CountryInput from '../shared/inputs/country'
import SlugsInput from '../shared/inputs/slugs'
import BoolInput from '../shared/inputs/bool'
import TextInput from '../shared/inputs/text'
import StringsInput from '../shared/inputs/strings'

import { processErrors, prepareComponents, prepareMutationInput, preprocessJSONInputsGently } from '../../utils'
import { getRule, getRules } from '../../access_rules'

interface UpdateAirlineInput {
  address?: string,
  brandColor?: string,
  countryId?: number,
  globalId: string,
  iata: string,
  icao?: string,
  isFlightable?: boolean,
  isLowcost: boolean,
  phones?: string[],
  seoTranslations?: Translations,
  slug: string,
  slugRu: string,
  translations: Translations,
  website: Website
}

export const schema = yup.object().shape({
  address: yup.string().nullable(),
  brandColor: yup.string().nullable().test('len', 'Brand color must be exactly 6 symbols long',
    val => !val || val.length === 6).matches(/^[A-F0-9]{6}$/, 'Brand color must contain only A-F letters and numbers'),
  countryId: yup.number().nullable().integer().positive(),
  globalId: yup.string().required().test('len', 'Global Id must be between 2 and 6 symbols',
    val => val.length >= 2 && val.length < 6).matches(/^[A-Z0-9]{2,5}$|^[А-Я0-9]{2,5}$/,
      'Global Id must contain either A-Z letters and numbers or А-Я letters and numbers'),
  iata: yup.string().required().length(2, 'IATA code must be exactly 2 symbols long').matches(/^[A-Z0-9]{2}$|^[А-Я0-9]{2}$/,
    'IATA must contain either A-Z letters and numbers or А-Я letters and numbers'),
  icao: yup.string().nullable().test('len', 'ICAO code must be exactly 3 letters long',
    val => !val || val.length === 3).matches(/^[A-Z]{3}$/,
      'only the following symbols are allowed in ICAO: A-Z'),
  isFlightable: yup.boolean().required(),
  isLowcost: yup.boolean().required(),
  phones: yup.array().of(yup.string()).nullable(),
  seoTranslations: yup.object().shape({}).nullable(),
  slug: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug En: a-z, 0-9, -'),
  slugRu: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug Ru: a-z, 0-9, -'),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation is required') }).required()
  }).required(),
  website: yup.object().required().shape({
    en: yup
    .string()
    .matches(/https?:\/\//, 'Website should start with https:// or http://')
    .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, 'Please enter a valid website URL')
  })
})

export default ({ match, initialInput, isDefaultInput }) => {
  const id = parseInt(match.params.id)
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateAirlineInput>(initialInput)

  const [updateAirline, { data: mutationData }] = useMutation(UpdateAirlineM, setErrors)
  const { data: airlineData, error, loading } = useQuery(AirlineQ, { variables: { id }, fetchPolicy: 'network-only' })

  if (error) return (<Alert type='error' message='Airline fetching error' />)
  if (loading) return (<Spin size='large' />)

  const { airline } = airlineData

  const serverDataInput = {
    address: airline.address,
    brandColor: airline.brandColor,
    countryId: airline.country?.id,
    globalId: airline.globalId,
    iata: airline.iata,
    icao: airline.icao,
    isFlightable: airline.isFlightable,
    isLowcost: airline.isLowcost,
    phones: airline.phones,
    seoTranslations: airline.seoTranslations,
    slug: airline.slugEn,
    slugRu: airline.slugRu,
    translations: airline.translations,
    website: airline.website
  }

  if (isDefaultInput(input)) setInput(serverDataInput)

  if (mutationData) history.push({
    pathname: `/airlines`,
    state: { mutationData: mutationData }
  })

  const mutationInput = prepareMutationInput(serverDataInput, input);

  const performMutation = () =>
    schema.validate(input)
      .then(_ => updateAirline({ variables: { id, input: preprocessJSONInputsGently(mutationInput) } }),
        e => processErrors(e, setErrors))

  const accessRules = getRules()
  const components = {
    translations: [
      {
        component: <TranslationsInput
          setTranslations={translations => setInput({ ...input, translations })}
          translations={input.translations}
        />,
        rule: getRule(accessRules, 'airline', 'translations')
      }
    ],
    seoTranslations: [
      {
        component: <TranslationsInput
          setTranslations={seoTranslations => setInput({ ...input, seoTranslations })}
          translations={input.seoTranslations}
          hideFetchWikiData={true}
        />,
        rule: getRule(accessRules, 'airline', 'seoTranslations')
      }
    ],
    changelog: [
      {
        component: <Changelog
          entityId={id}
          entity='AIRLINE'
        />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ],
  }

  return (
    <>
      <Card
        tabList={[
          { key: 'main', tab: 'Main' },
          { key: 'translations', tab: 'Translations' },
          { key: 'seoTranslations', tab: 'SEO Translations' },
          { key: 'changelog', tab: 'Changelog' }
        ]}
        onTabChange={key => setActiveTab(key)}
        className='form'
      >

        {errors.map(e => <Alert type='error' message={e} key={e} />)}

        {
          {
            main: <MainForm
              setInput={setInput}
              input={input}
              accessRules={accessRules}
            />,
            translations: prepareComponents('translations', components.translations),
            seoTranslations: prepareComponents('seoTranslations', components.seoTranslations),
            changelog: prepareComponents('changelog', components.changelog),
          }[activeTab]
        }

        {
          (activeTab !== 'changelog') &&
          <>
            <Button
              style={{ float: 'left' }}
              onClick={() => setInput(serverDataInput)}
              children='Reset Changes'
              disabled={equal(input, serverDataInput)}
            />

            <Button
              style={{ float: 'right' }}
              onClick={performMutation}
              children='Update airline'
              type='primary'
            />
          </>
        }
      </Card>
    </>
  )
}

const MainForm = ({ input, setInput, accessRules }) => {
  const { data: inputsData, error, loading } = useQuery(CountriesQ)

  if (error) return (<Alert type='error' message='Error fetching inputs data' />)
  if (loading) return (<Spin size='large' />)

  const HEX_COLOR_REG_EXP = /[^0-9A-F]/i

  const inputComponents = [
    {
      component: <BoolInput
        label='is lowcost'
        checked={input.isLowcost}
        onChange={val => setInput({ ...input, isLowcost: val })}
      />,
      rule: getRule(accessRules, 'airline', 'isLowcost')
    },
    {
      component: <BoolInput
        label='is flightable'
        checked={!!input.isFlightable}
        onChange={val => setInput({ ...input, isFlightable: val })}
      />,
      rule: getRule(accessRules, 'airline', 'isFlightable')
    },
    {
      component: <TextInput
        label='IATA'
        placeholder='IATA code, example: AB'
        value={input.iata}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-ZА-Я0-9]/i, '').toUpperCase()
          if (validatedValue.length <= 2) return setInput({ ...input, iata: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'airline', 'iata')
    },
    {
      component: <TextInput
        label='ICAO'
        placeholder='ICAO code, example: ABC'
        value={input.icao}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-Z]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, icao: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'airline', 'icao')
    },
    {
      component: <TextInput
        label='Global Id'
        placeholder='Global Id, example: AB'
        value={input.globalId}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-ZА-Я0-9]/i, '').toUpperCase()
          if (validatedValue.length <= 5) return setInput({ ...input, globalId: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'airline', 'globalId')
    },
    {
      component: <TextInput
        label='address'
        value={input.address}
        onChange={({ target: { value } }) => setInput({ ...input, address: value })}
      />,
      rule: getRule(accessRules, 'airline', 'address')
    },
    {
      component: <StringsInput
        label='phones'
        values={input.phones}
        disabled={loading}
        onChange={phones => setInput({ ...input, phones })}
      />,
      rule: getRule(accessRules, 'airline', 'phones')
    },
    {
      component: <CountryInput
        countries={inputsData.countries}
        value={input.countryId || -1}
        disabled={loading}
        onChange={countryId => setInput({ ...input, countryId })}
      />,
      rule: getRule(accessRules, 'airline', 'country')
    },
    {
      component: <SlugsInput
        slugRuVal={input.slugRu}
        slugVal={input.slug}
        onSlugRuChange={({ target: { value } }) => setInput({ ...input, slugRu: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        onSlugChange={({ target: { value } }) => setInput({ ...input, slug: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
      />,
      rule: getRule(accessRules, 'airline', 'slug')
    },
    {
      component: <TextInput
        label='brand color'
        placeholder='6-symbol HEX color code, example: FFFFFF'
        value={input.brandColor}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(HEX_COLOR_REG_EXP, '').toUpperCase()
          if (validatedValue.length <= 6) return setInput({ ...input, brandColor: validatedValue })
        }}
      />,
      rule: getRule(accessRules, 'airline', 'brandColor')
    },
    {
      component: <TextInput
        label='website'
        placeholder="Airline's website, example: https://example.com"
        value={input.website.en}
        onChange={({ target: { value } }) => {
          if (value.length === 0 ) {
            return setInput({ ...input, website: {} })
          } else {
            return setInput({ ...input, website: {"en": value} })
          }
        }}
      />,
      rule: getRule(accessRules, 'airline', 'website')
    }
  ]

  return (
    <>
      {
        prepareComponents('main-form', inputComponents)
      }
    </>
  )
}
