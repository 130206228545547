import React from 'react'
import { Link } from 'react-router-dom'
import { ListMarketsQ } from './queries'
import { Country, Locale, City } from '../../types'
import IndexList from '../shared/index_list'
import { prepareComponents } from '../../utils'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../access_rules'
import { Alert } from 'antd'
interface ListMarketsResponse {
  id: number,
  code: string,
  host: string,
  country: Country,
  locale: Locale
}

interface ListMarketsTableRow {
  id: number,
  code: string,
  host: string,
  country: Country,
  locale: Locale,
  capital?: City
}
const responseToTable = (data: Array<ListMarketsResponse>): Array<ListMarketsTableRow> => {
  return data.map(({ code, host, id, country, locale }) => ({
    code,
    id,
    host,
    country,
    locale,
    capital: country.capital
  }))
}

export default (props: any) => {
  const accessRules = getRules()

  const createdMarketId = props.location?.state?.createdId
  const columns = [
    {
      title: "code",
      dataIndex: "code",
      key: "code",
      hidden: notAllowedToShow(accessRules, 'market', 'code')
    },
    {
      title: "host",
      dataIndex: "host",
      key: "host",
      hidden: notAllowedToShow(accessRules, 'market', 'host')
    },
    {
      title: "locale",
      dataIndex: "locale",
      key: "locale",
      hidden: notAllowedToShow(accessRules, 'market', 'locale'),
      render: (locale: Locale) =>
        locale && locale.name ?
          <span>{locale.name}</span>
          :
          <span>Locale name does not exist</span>
    },
    {
      title: "Capital",
      dataIndex: "capital",
      key: "capital",
      hidden: notAllowedToShow(accessRules, 'market', 'capital'),
      render: (capital: City) =>
        capital && capital.translations ?
          <Link to={`/cities/${capital.id}`}>{capital.translations.en.su}</Link>
          :
          <span>Capital does not have translations</span>
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      hidden: notAllowedToShow(accessRules, 'market', 'country'),
      render: (country: Country) =>
        country && country.translations ?
          <Link to={`/countries/${country.id}`}>{country.translations.en.su}</Link>
          :
          <span>no translations</span>
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: 60,
      render: (id: number) =>
        <span><Link to={`/markets/${id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'market')
    }
  ]

  return (
    <>
      {createdMarketId && <Alert closable type='success' message={`Market with id ${createdMarketId} created successfully`}></Alert>}
      {
        prepareComponents('markets-list', [{
          component: <IndexList
            request={{ query: ListMarketsQ, datakey: 'markets', transform: responseToTable }}
            columns={columns}
            title='Markets'
            rowKey='id'
            newEntityPath='/markets/new'
            showCreateButton={accessRules['__all_mutations']}
          />,
          rule: getRule(accessRules, 'query', 'markets')
        }])
      }
    </>
  )

}
