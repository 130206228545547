import React from 'react'
import {Form, Select} from 'antd'

interface StationTypeInputProps {
    onChange: (e: any) => void, 
    disabled: boolean, 
    value: string,
    placeholder?: string,
    stationTypes: string[]
}

export default ({value, placeholder, stationTypes, disabled, onChange}: StationTypeInputProps) =>
  <Form.Item label='Station type'>
    <Select
      value={value}
      disabled={disabled}
      placeholder={placeholder}            
      onChange={onChange}
    >
      {stationTypes.map(type =>
        <Select.Option value={type} key={type}>{type}</Select.Option>
      )}
    </Select>
  </Form.Item>
  