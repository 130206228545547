import gql from "graphql-tag";

export const ListVisaRequirementsQ = gql`
  query GetVisaRequirementsArkAdmin {
    visaRequirements {
      id
      duration
      type
      requirement
      destinationCountry {
        id
        translations(filter: { locales: ["en"] })
      }
      citizenCountry {
        id
        translations(filter: { locales: ["en"] })
      }
    }
  }
`;

export const VisaRequirementQ = gql`
  query GetVisaRequirementByIDArkAdmin($id: Int!) {
    visaRequirement(id: $id) {
      id
      duration
      requirement
      destinationCountry {
        id
        translations(filter: { locales: ["en"] })
      }
      citizenCountry {
        id
        translations(filter: { locales: ["en"] })
      }
    }
  }
`;

export const CountriesQ = gql`
  query GetCountriesArkAdmin {
    countries {
      id
      translations(filter: { locales: ["en"] })
    }
  }
`;
