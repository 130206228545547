import React from 'react'
import { Link } from 'react-router-dom'
import { ListLanguagesQ } from './queries'
import { Translations } from '../../../types'
import IndexList from '../../shared/index_list'
import { prepareComponents } from '../../../utils'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../../access_rules'
import { Alert } from 'antd'

interface ListLanguagesResponse {
  id: number,
  code: string,
  translations: Translations
}

interface ListLanguagesTableRow {
  id: number,
  code: string,
  translations: Translations
}

const responseToTable = (data: Array<ListLanguagesResponse>): Array<ListLanguagesTableRow> =>
  data.map(({ code, id, translations }) => ({ code, translations, id }))

export default (props: any) => {
  const createdLanguageId = props.location?.state?.createdId
  const updatedLanguageCode = props.location?.state?.mutationData?.updateLanguage?.code

  const accessRules = getRules()

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      hidden: notAllowedToShow(accessRules, 'language', 'code')
    },
    {
      title: "Name",
      dataIndex: "translations",
      key: "name",
      hidden: notAllowedToShow(accessRules, 'language', 'translations'),
      render: (translations: Translations) =>
        <span>{translations?.en?.su}</span>
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: 60,
      render: (id: number) =>
        <span><Link to={`/languages/${id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'language')
    }
  ]

  return (
    <>
      {createdLanguageId && <Alert closable type='success' message={`Language with id ${createdLanguageId} created successfully`}></Alert>}
      {updatedLanguageCode && <Alert closable type='success' message={`Language ${updatedLanguageCode} updated successfully`}></Alert>}
      {
        prepareComponents('languages-list', [{
          component: <IndexList
            request={{ query: ListLanguagesQ, datakey: 'languages', transform: responseToTable }}
            title="Languages"
            newEntityPath='/languages/new'
            showCreateButton={accessRules['__all_mutations']}
            columns={columns}
            rowKey='id'
            filterFields={[
              { key: 'name', path: 'translations' }
            ]}
          />,
          rule: getRule(accessRules, 'query', 'languages')
        }])
      }
    </>
  )
}
