import React, { useState } from 'react'
import { useMutation } from '../../mutation'
import { useQuery } from '../../query'
import { CreateMarketM } from './mutations'
import { LocalesCountriesQ } from './queries'
import { useHistory } from 'react-router-dom'
import { Button, Spin, Card, Alert } from 'antd'
import * as yup from 'yup'
import CountryInput from '../shared/inputs/country'
import LocaleInput from '../shared/inputs/locale'
import TextInput from '../shared/inputs/text'

import { processErrors } from "../../utils"

import '../shared/form.css'

type CreateMarketInput = {
  code?: string,
  host?: string,
  localeId?: number,
  countryId?: number
}

export const schema = yup.object().shape({
  code: yup.string().required(),
  host: yup.string()
    .matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/, { message: "Hostname is not valid" })
    .required(), // https://www.regexpal.com/?fam=93928
  localeId: yup.number().required(),
  countryId: yup.number().required()
})

export default () => {
  let history = useHistory()

  const { loading, error: qError, data: inputsData } = useQuery(LocalesCountriesQ)
  const [input, setInput] = useState<CreateMarketInput>({})
  const [errors, setErrors] = useState<Array<string>>([])
  const [createMarket, { data: mutationData }] = useMutation(CreateMarketM, setErrors)

  const performMutation = () =>
    schema.validate(input)
      .then(_ => createMarket({ variables: { input } }),
        e => processErrors(e, setErrors))

  if (loading) return (<Spin />)
  if (qError) return (<Alert type='error' message='Fetching error' />)

  if (mutationData) history.push({
    pathname: `/markets`,
    state: { createdId: mutationData.createMarket.id }
  })

  return (
    <Card
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} />)}

      <TextInput
        label='host'
        value={input.host || null}
        onChange={e => setInput({ ...input, host: e.target.value })}
      />

      <TextInput
        label='code'
        value={input.code || null}
        onChange={e => setInput({ ...input, code: e.target.value })}
      />

      <CountryInput
        value={input.countryId || -1}
        disabled={loading}
        onChange={countryId => setInput({ ...input, countryId })}
        countries={inputsData.countries}
      />

      <LocaleInput
        value={input.localeId || -1}
        disabled={loading}
        onChange={localeId => setInput({ ...input, localeId })}
        locales={inputsData.locales}
      />

      <Button
        style={{ float: 'left' }}
        onClick={() => setInput({})}
        children='Reset Changes'
      />

      <Button
        disabled={false}
        type='primary'
        htmlType='submit'
        style={{ float: 'right' }}
        children='Save'
        onClick={performMutation}
      />
    </Card>
  )
}
