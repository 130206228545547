import gql from 'graphql-tag'

export const ListMarketsQ = gql`
  query {
      markets {
          id
          code 
          host 
          locale {id name}
          
          country {
              id 
              translations(filter: {locales: ["en", "ru"]})

              capital {
                  id 
                  translations(filter: {locales: ["en", "ru"]})
              }
          }

      }
  }
`

export const LocalesCountriesQ = gql`
  query {
      locales {
          id 
          name
      }

      countries {
          id 
          iata
          translations(filter: {locales: ["en", "ru"]})
      }
  }
`

export const MarketQ = gql`
  query GetMarket($id: Int!){
      market(id: $id){
          id 
          code
          host

          country { id }
          locale { id }
      }
  }
`
