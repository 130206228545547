import React from 'react'
import {Form, Input} from 'antd'

interface TextInputProps {
    label: string,
    value: any,
    onChange: (_) => void
    placeholder?: string,
    disabled?: boolean
}

export default (props: TextInputProps) =>
  <Form.Item label={props.label}>
    <Input
      placeholder={props.placeholder || ''}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled || false}
    />
  </Form.Item>
