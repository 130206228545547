import gql from 'graphql-tag'

export const ListAirportsQ = gql`
  query {
      airports {
          id
          translations(filter: {locales: ["en", "ru"]})
          iata
          type
          city {
              id
              iata
              translations(filter: {locales: ["en", "ru"]})
              country {
                  id
                  iata
                  translations(filter: {locales: ["en", "ru"]})
              }
          }
      }
  }
`

export const AirportQ = gql`
  query GetAirport($id: Int!){
      airport(id: $id){
          id
          translations
          seoTranslations
          iata
          type
          isFlightable
          timeZone
          coordinates
          slugRu: slug(marketCode: "ru")
          slugEn: slug(marketCode: "us")
          hasTransitZone
          transitWorkHoursMin
          transitWorkHoursMax

          city{
              id
              iata
              translations(filter: {locales: ["en", "ru"]})
          }

          metropoly {
              id
              iata
          }
      }
  }
`

export const InputsDataQ = gql`
  query {
      cities {id iata translations}
      glossary { timeZones stationTypes}
  }
`

export const UpdateInputsDataQ = gql`
  query UpdateInputsData($id: Int!) {
      cities {id translations}
      glossary {timeZones stationTypes}

      airport(id: $id){
          id
          iata
          type
          coordinates

          city {
              id
              translations
          }
      }
  }
`
