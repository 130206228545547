import { getJWT, updateJWT } from "pios-client"
import { ARK_ACCESS_RULES_KEY, ARK_ACCESS_RULES_URL } from './constants';

interface LoadRulesResult {
  rulesLoaded: boolean,
  responseStatus?: number,
  runtimeError: boolean
}

export async function loadRules(): Promise<LoadRulesResult> {
  try {
    const response = await fetch(ARK_ACCESS_RULES_URL, { headers: { "authorization": `Bearer ${getJWT()}` } })
    if (response.status === 200) {
      const rules = await response.json()
      saveRules(rules)
      return { rulesLoaded: true, responseStatus: 200, runtimeError: false }
    } else if (response.status === 401) {
      await updateJWT()
      return { rulesLoaded: false, responseStatus: 401, runtimeError: false }
    } else {
      return { rulesLoaded: false, responseStatus: response.status, runtimeError: false }
    }
  } catch (error) {
    console.error(error)
    return { rulesLoaded: false, runtimeError: true }
  }
}

function saveRules(rules: Object) {
  localStorage.setItem(ARK_ACCESS_RULES_KEY, JSON.stringify(rules))
}

export function getRules(): Object {
  return JSON.parse(localStorage.getItem(ARK_ACCESS_RULES_KEY) || "{}")
}

export function getRule(accessRules: object, entity: string, field: string): 'edit' | 'show' | 'disallow' {
  if (accessRules['__all_mutations'] === true) return 'edit';
  if (accessRules['__all_queries'] === true) return 'show';

  const entityRules = accessRules[entity] || {};
  const rule = entityRules[field];

  return rule === undefined ? 'disallow' : rule;
}

export function notAllowedToShow(accessRules: object, entity: string, field: string): boolean {
  return !allowedToShow(accessRules, entity, field)
}

export function allowedToShow(accessRules: object, entity: string, field: string): boolean {
  const rule = getRule(accessRules, entity, field)
  return rule === 'show' || rule === 'edit'
}

export function notAllowedToEdit(accessRules: object, entity: string): boolean {
  return !allowedToEdit(accessRules, entity)
}

export function allowedToEdit(accessRules: object, entity: string): boolean {
  if (accessRules['__all_mutations'] === true) return true;

  const entityRules = accessRules[entity] || {};
  return Object.keys(entityRules).some(key => entityRules[key] === 'edit')
}
