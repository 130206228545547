import React, { useEffect, useState } from 'react';
import { ApolloProvider } from "@apollo/react-hooks"
import client from './gql_client'
import Router from './router'
import { checkJWT, setConfiguration } from 'pios-client'
import { loadRules } from './access_rules';
import { ARK_ENVIRONMENT } from './constants';

interface Error {
  msg?: string
}

export default () => {
  const [jwtChecked, setJwtChecked] = useState(false);
  const [rulesLoaded, setRulesLoaded] = useState(false);
  const [error, setError] = useState<Error>({})

  useEffect(() => {
    setConfiguration({ environment: ARK_ENVIRONMENT })
    checkJWT()
      .then(token => {
        if (token !== undefined) {
          setJwtChecked(true)
          loadRules().then((result) => {
            if (result.rulesLoaded) {
              setRulesLoaded(true)
            } else {
              if (result.responseStatus === 401) {
                window.location.reload()
              }
              if (result.responseStatus === 403) {
                setError({ msg: "Forbidden" })
              }
              if (result.runtimeError) {
                setError({ msg: "Something went wrong. Check your console" })
              }
            }
          })
        }
      })
  }, []);

  if (error.msg) {
    return (<div>{error.msg}</div>)
  } else if (jwtChecked && rulesLoaded) {
    return (<ApolloProvider client={client}>
      <Router />
    </ApolloProvider>)
  } else {
    return (<div>Loading...</div>)
  }
}
