import React from 'react'
import { Link } from 'react-router-dom'

import { Airline, Country, Translations } from '../../types'
import { ListAirlinesQ } from './queries'
import IndexList from '../shared/index_list'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../access_rules'
import { prepareComponents } from '../../utils'
import { Alert } from 'antd'

interface ListCountriesResponse {
  id: number,
  iata: string,
  icao: string,
  translations: Translations,
  country: Country
}

interface ListCountriesTableRow {
  id: number,
  iata: string,
  icao: string,
  airline: Airline,
  country: Country
}

const responseToTable = (data: Array<ListCountriesResponse>): Array<ListCountriesTableRow> => {
  return data.map(({ id, iata, icao, translations, country }) => ({
    iata,
    id,
    icao,
    airline: { id, translations },
    country
  }))
}

export default (props: any) => {
  const updatedAirlineIata = props.location?.state?.mutationData?.updateAirline?.iata
  const createdAirlineId = props.location?.state?.createdId
  const accessRules = getRules()

  const columns = [
    {
      title: "iata",
      dataIndex: "iata",
      key: "iata",
      width: 60,
      hidden: notAllowedToShow(accessRules, 'airline', 'iata')
    },
    {
      title: "icao",
      dataIndex: "icao",
      key: "icao",
      width: 50,
      hidden: notAllowedToShow(accessRules, 'airline', 'icao')
    },
    {
      title: "name",
      dataIndex: "airline",
      key: "name",
      hidden: notAllowedToShow(accessRules, 'airline', 'translations'),
      render: (airline: Airline) =>
        airline && airline.translations ?
          <span>{airline.translations.en?.su}</span>
          :
          <span>no translation </span>
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      hidden: notAllowedToShow(accessRules, 'airline', 'country'),
      render: (country: Country) =>
        country && country.translations ?
          <Link to={`/countries/${country.id}`}>{country.translations.en.su}</Link>
          :
          <span>No country</span>
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: 60,
      render: (id: number) =>
        <span><Link to={`/airlines/${id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'airline')
    }
  ]

  return (
    <>
      {createdAirlineId && <Alert closable type='success' message={`Airline with id ${createdAirlineId} created successfully`}></Alert>}
      {updatedAirlineIata && <Alert closable type='success' message={`Airline ${updatedAirlineIata} updated successfully`}></Alert>}
      {
        prepareComponents('airlines-list', [{
          component: <IndexList
            columns={columns}
            request={{ query: ListAirlinesQ, datakey: 'airlines', transform: responseToTable }}
            newEntityPath='/airlines/new'
            showCreateButton={accessRules['__all_mutations']}
            title='Airlines'
            filterFields={
              [{ key: 'iata', path: 'iata' },
              { key: 'icao', path: 'icao' },
              { key: 'name', path: 'airline.translations' }
              ]}
            rowKey='id'
          />,
          rule: getRule(accessRules, 'query', 'airlines')
        }])
      }
    </>
  )
}
