import React, { useState } from 'react'
import {Form, Select} from 'antd'

interface TimezoneInputProps {
    onChange: (e: any) => void
    disabled: boolean,
    value: string,
    placeholder?: string,
    timeZones: string[]
}
export default ({onChange, disabled, value, placeholder, timeZones}: TimezoneInputProps) => {
  const [searchVal, setSearchVal] = useState('')

  return (
    <Form.Item label='Timezone'>
      <Select
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        onSearch={v => setSearchVal(v)}
        filterOption={false}
        showSearch={true}
      >
        {
          timeZones
          .filter(tz => tz.toLowerCase().includes(searchVal.toLowerCase()))
          .map(tz =>
          <Select.Option value={tz} key={tz}>{tz}</Select.Option>
        )}
      </Select>
    </Form.Item>
  )
}

