import React from "react";
import { Form, Input } from "antd";

import { CountryContentTexts } from "../../../types";
import { COUNTRY_CONTENT_TEXTS_TYPES } from '../../../constants'

interface Props {
  onChange: (_) => void,
  contentTexts?: CountryContentTexts,
  disabled?: boolean
}

export default ({ contentTexts, onChange, disabled }: Props) =>
  <div style={{ marginBottom: "10px" }}>
    {COUNTRY_CONTENT_TEXTS_TYPES.map(textType =>

      <Form.Item
        key={textType}
        label={textType}
      >
        <Input.TextArea
          key={`${textType}-text`}
          name={textType}
          value={contentTexts && contentTexts[textType] ? contentTexts[textType] : ''}
          disabled={disabled || false}
          onChange={evt => onChange({ ...contentTexts, [textType]: evt.target.value })}
        />
      </Form.Item>
    )}
  </div>
