import React, {useState} from 'react'
import {Form, Select} from 'antd'

interface LocaleInputProps {
    onChange: (_) => void, 
    value: number,
    disabled: boolean,
    locales: Array<{id: number, name: string}>
}

export default ({onChange, value, disabled, locales}: LocaleInputProps) => {
  const [searchVal, setSearchVal] = useState('')

  return (
    <Form.Item label='Locale'>
        <Select
          disabled={disabled}
          onChange={onChange}
          value={value}
          filterOption={false}
          onSearch={v => setSearchVal(v)}
          showSearch={true}
        >
          <Select.Option value={-1} key='null'>
            Not selected
          </Select.Option>

          {
           locales
           .filter(l => l.name.includes(searchVal))
           .map(({id, name}) =>
            <Select.Option value={id} key={`locale-${id}`}>{name}</Select.Option>
          )}
        
        </Select>
    </Form.Item>
  )
}
    
