import React from 'react'
import { Link } from 'react-router-dom'
import { ListVisaRequirementsQ } from './queries'
import IndexList from '../shared/index_list'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../access_rules'
import { Country } from '../../types'
import { prepareComponents } from '../../utils'
import { Alert } from 'antd'

interface ListVisaRequirementsResponse {
  id: number,
  duration: number,
  requirement: string,
  citizenCountry: Country,
  destinationCountry: Country
}


interface ListVisaRequirementsTableRow {
  id: number,
  duration: number,
  requirement: string,
  citizenCountry: Country,
  destinationCountry: Country
}


export default (props) => {
  const accessRules = getRules()

  const createdVisaId = props.location?.state?.createdId
  const updatedVisaId = props.location?.state?.mutationData?.updateVisaRequirement?.id

  const columns = [
    {
      title: "Citizen Country",
      dataIndex: "citizenCountry",
      key: "citizenCountry",
      hidden: notAllowedToShow(accessRules, 'visaRequirements', 'citizenCountry'),
      render: (citizenCountry: Country) =>
        citizenCountry && citizenCountry.translations ?
          <Link to={`/countries/${citizenCountry.id}`}>{citizenCountry.translations.en.su}</Link>
          :
          <span>no translations</span>
    },
    {
      title: "Destination Country",
      dataIndex: "destinationCountry",
      key: "destinationCountry",
      hidden: notAllowedToShow(accessRules, 'visaRequirements', 'destinationCountry'),
      render: (destinationCountry: Country) =>
        destinationCountry && destinationCountry.translations ?
          <Link to={`/countries/${destinationCountry.id}`}>{destinationCountry.translations.en.su}</Link>
          :
          <span>no translations</span>
    },
    {
      title: "Requirement",
      dataIndex: "requirement",
      key: "requirement",
      hidden: notAllowedToShow(accessRules, 'visaRequirements', 'requirement'),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      hidden: notAllowedToShow(accessRules, 'visaRequirements', 'duration'),
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: 60,
      render: (id: number) =>
        <span><Link to={`/visa_requirements/${id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'visaRequirement')
    }
  ]

  return (
    <>
    {createdVisaId && <Alert closable type='success' message={`Visa requirement with id ${createdVisaId} created successfully`}></Alert>}

    {updatedVisaId && <Alert closable type='success' message={`Visa requirement ${updatedVisaId} updated successfully`}></Alert>}
      {
        prepareComponents('visa-requirements-list', [{
          component: <IndexList
            request={
              {
                query: ListVisaRequirementsQ,
                datakey: 'visaRequirements',
                transform: (data: Array<ListVisaRequirementsResponse>): Array<ListVisaRequirementsTableRow> => {
                  return data.map(({ id, duration, destinationCountry, citizenCountry, requirement }) => ({
                    id, duration, destinationCountry, citizenCountry, requirement
                  }))
                }
              }
            }
            columns={columns}
            title='Visa Requirements'
            rowKey='id'
            newEntityPath='/visa_requirements/new'
            filterFields={[
              { key: 'destinationCountry', path: 'destinationCountry.translations' },
              { key: 'citizenCountry', path: 'citizenCountry.translations' },
            ]}
            showCreateButton={!notAllowedToEdit(accessRules, 'visaRequirement')}
            filterIntersection={true}
          />,
          rule: getRule(accessRules, 'query', 'visaRequirements')
        }])
      }
    </>
  )

}
