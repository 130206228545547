import React from 'react'
import { Link } from 'react-router-dom'
import { ListCountriesQ } from './queries'
import { Country, Translations, City } from '../../../types'
import IndexList from '../../shared/index_list'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../../access_rules'
import { prepareComponents } from '../../../utils'
import { Alert } from 'antd'

interface ListCountriesResponse {
  id: number,
  iata: string,
  iso: string,
  translations: Translations,
  capital: City
}

interface ListCountriesTableRow {
  iata: string,
  country: Country,
  capital: City
}

const responseToTableData = (data: Array<ListCountriesResponse>): Array<ListCountriesTableRow> => {
  return data.map(({ iata, iso, id, translations, capital }) => ({ iata, iso, country: { iata, translations, id }, capital }))
}

export default (props: any) => {
  const createdCountryId = props.location?.state?.createdId
  const updatedContentTexts = !!props.location?.state?.mutationData?.updateCountryContentTexts
  const updatedCountryIata = props.location?.state?.mutationData?.updateCountry?.iata

  const accessRules = getRules()

  const columns = [
    {
      title: "iata",
      dataIndex: "iata",
      key: "iata",
      hidden: notAllowedToShow(accessRules, 'country', 'iata')
    },
    {
      title: "iso",
      dataIndex: "iso",
      key: "iso",
      hidden: notAllowedToShow(accessRules, 'country', 'iso')
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      hidden: notAllowedToShow(accessRules, 'country', 'translations'),
      render: (country: Country) =>
        country && country.translations ?
          <span>{country.translations?.en?.su}</span>
          :
          <span>No translation</span>
    },
    {
      title: "Capital",
      dataIndex: "capital",
      key: "capital",
      hidden: notAllowedToShow(accessRules, 'country', 'capital'),
      render: (capital: City) =>
        capital && capital.translations ?
          <Link to={`/cities/${capital.id}`}>{capital.translations.en.su}</Link>
          :
          <span>Capital not set or its translation does not exist</span>
    },
    {
      title: "Actions",
      dataIndex: "country",
      width: 60,
      render: (country: Country) =>
        <span><Link to={`/countries/${country.id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'country') && getRule(accessRules, 'query', 'countryContentTexts') !== 'edit'
    }
  ]

  return (
    <>
      {createdCountryId && <Alert closable type='success' message={`Country with id ${createdCountryId} created successfully`}></Alert>}
      {updatedCountryIata && <Alert closable type='success' message={`Country ${updatedCountryIata} updated successfully`}></Alert>}
      {updatedContentTexts && <Alert closable type='success' message={`Content Texts updated successfully`}></Alert>}

      {
        prepareComponents('countries-list', [{
          component: <IndexList
            request={{ query: ListCountriesQ, datakey: 'countries', transform: responseToTableData }}
            title='Countries'
            newEntityPath='/countries/new'
            showCreateButton={accessRules['__all_mutations']}
            columns={columns}
            rowKey='iata'
            filterFields={[
              { key: 'iata', path: 'iata' },
              { key: 'iso', path: 'iso' },
              { key: 'country', path: 'country.translations' }
            ]}
          />,
          rule: getRule(accessRules, 'query', 'countries')
        }])
      }
    </>
  )
}
