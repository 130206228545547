import React, { useState } from 'react'
import { Form, Select } from 'antd'
import { Translations } from '../../../types'
import { translationsContainString } from '../../../utils'
import LatestChange, {Data as LatestChangeData} from "../latest_change";

interface CityInputProps {
    onChange: (e: any) => void,
    value?: number | number[],
    disabled: boolean,
    label?: string,
    formItemDisabled?: boolean,
    cities: Array<{ id: number, iata: string, translations: Translations }>,
    multi?: boolean,
    latestChange?: LatestChangeData
}

export default ({ onChange, label, formItemDisabled, value, disabled, cities, multi, latestChange}: CityInputProps) => {
  const [searchVal, setSearchVal] = useState('')

  const select = (
    <Select
      mode={multi ? "multiple" : undefined}
      disabled={disabled}
      value={value}
      style={{width: '80%', alignSelf: 'center'}}
      onChange={onChange}
      filterOption={false}
      onSearch={v => setSearchVal(v)}
      showSearch={true}
    >
      {!multi &&
      <Select.Option value={-1} key='null'>
        Not selected
      </Select.Option>
      }

      {
        cities
          .filter(c =>
            c.iata.includes(searchVal.toUpperCase()) ||
            translationsContainString(c.translations, searchVal)
          )
          .map(({id, translations}) =>
            <Select.Option value={id} key={id}>{translations?.en?.su}</Select.Option>
          )}
    </Select>
  )

  if (formItemDisabled)
    return select
  else
    return <Form.Item label={label ? label : 'City'}>
      {select}
      <LatestChange data={latestChange} />
  </Form.Item>
}
