import gql from 'graphql-tag'

export const CreateLocaleM = gql`
  mutation CreateLocale($input: CreateLocaleInput!){
      createLocale(input: $input) { id }
  }
`
export const UpdateLocaleM = gql`
  mutation UpdateLocale($input: UpdateLocaleInput!, $id: Int!){
    updateLocale(input: $input, id: $id) { id }
  }
`  
