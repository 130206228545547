import React, {useState} from 'react'
import { Form, Input, Button, Select } from 'antd'
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import { Coordinates } from '../../../types'
import { useDebounce } from "react-use";

const accessToken = 'pk.eyJ1Ijoic2FjcmVkc3RlZWwiLCJhIjoiY2lwODhxM3p4MDE4ZnRjbHlnZXVnYTk3ZSJ9.NKcJjzBU-cRXfqunIv9e1g'

type Maybe<T> = T | null

interface CoordinatesInputProps {
    onChange: (_) => void,
    value: Coordinates,
    disabled?: boolean
}

export default ({onChange, value, disabled}: CoordinatesInputProps ) => {
  const [mapOpen, setMapOpen] = useState<boolean>(false)
  const [city, setCity] = useState<number>(-1)
  const [options, setOptions] = useState<any[]>([])
  const [searchCity, setSearchCity] = useState<string>('')

  const getSearchResults = async () => {
    try {
      const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchCity)}.json?access_token=${accessToken}&limit=20&types=country,region,district,place,locality,neighborhood,address`)
      const json = await response.json()
      setOptions(json.features.map(f => ({ coordinates: {lon: f.center[0], lat: f.center[1]}, id: f.id, name: f.place_name })))
    } catch (e) {
      console.error(`Cannot get search result`, e)
    }
  }

  useDebounce(
    () => {
      getSearchResults()
    },
    300,
    [searchCity]
  );

  return (
    <Form.Item label='Coordinates'>
      <Input
        style={{ width: 150, textAlign: 'center', marginRight: '10px'}}
        type='number'
        value={value?.lat?.toFixed(7) || 0}
        onChange={evt => onChange({...value, lat: parseFloat(evt.target.value)})}
        disabled={disabled || false}
      />

      <Input
        style={{ width: 150, textAlign: 'center', marginRight: '10px'}}
        value={value?.lon?.toFixed(5) || 0}
        type='number'
        onChange={evt => onChange({...value, lon: parseFloat(evt.target.value)})}
        disabled={disabled || false}
      />

      <Button
        children={mapOpen ? 'Close map' : 'Choose on map'}
        onClick={() => setMapOpen(!mapOpen)}
        disabled={disabled || false}
      />

      <div style={{ marginTop: 6 }}>
        <Form.Item label="Find by place">
          <Select
            disabled={disabled}
            value={city}
            style={{width: '80%'}}
            onChange={val => {
              const target = options.find(c => c.id === val)
              if (!target) {
                return
              }
              setCity(val)
              onChange(target.coordinates)
            }}
            onSearch={val => {
              setSearchCity(val)
            }}
            filterOption={false}
            placeholder="Find by place"
            showSearch={true}
          >
            <Select.Option value={-1} key='null'>
              Start typing...
            </Select.Option>

            {options
              .map(({id, name}) =>
                <Select.Option value={id!} key={id}>{name}</Select.Option>
              )}
          </Select>
        </Form.Item>
      </div>

      {
        mapOpen &&
         <MapContainer
           coordinates={value}
           onCoordinatesChange={onChange}
         />
      }
    </Form.Item>
  )
}

interface MapContainerProps {
  coordinates: Coordinates,
  onCoordinatesChange: (_) => void
}

const MapContainer = ({coordinates, onCoordinatesChange}: MapContainerProps) => {
  const Map = ReactMapboxGl({
    accessToken
  })
  
  return <Map
   style="mapbox://styles/mapbox/streets-v9"  // eslint-disable-line
   containerStyle={{
    float: 'left',
    height: 400,
    marginTop: 10,
    width: '100%',
  }}
  onDragEnd={map => {
    const {lat, lng: lon} = map.getCenter()
    onCoordinatesChange({lat, lon})
  }}
  center={coordinatesToArray(coordinates)}
 >
   <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
     <Feature
       coordinates={coordinatesToArray(coordinates)}
     />
   </Layer>
 </Map>
}

const coordinatesToArray = (value: Maybe<Coordinates>): [number, number] => {
  if (value === null) {
    return [37.61778, 55.75583]
  }

  let lon = value.lon || 0
  let lat = value.lat || 0

  if (lon < -180) { lon = -180 }
  if (lon > 180) { lon = 180 }

  if (lat < -90) { lat = -90 }
  if (lat > 90) { lat = 90 }

  return [lon, lat]
}
