import React, { useState } from 'react'
import { Table, Input, Button, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import get from 'lodash.get'

import ListTitle from './ListTitle'
import { useQuery } from '../../query'
import { DocumentNode } from 'graphql';

interface SortField {
  key: string, sorter?: (a, b) => number
  directions?: Array<"descend" | "ascend">
  default?: "descend" | "ascend"
}

interface IndexListProps {
  request: { query: DocumentNode, datakey: string, transform: (data: any) => any }
  columns: Array<any>,
  title: string,
  newEntityPath: string,
  showCreateButton?: boolean,
  filterFields?: Array<{ key: string, path: string }>,
  size?: string,
  rowKey: string,
  scroll?: { x?: string | number, y?: string | number },
  sortFields?: Array<SortField>,
  selectFilter?: Array<{ key: string, defaultValue: string[], filterValues: string[] }>
  filterIntersection?: boolean
}

interface DropdownProps {
  onApply: (_) => void,
  onReset: (_) => void,
  onChange: (_) => void,
  value?: string,
  refSet: (_) => void
}

const Dropdown = ({ value, onApply, onChange, onReset, refSet }: DropdownProps) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        value={value}
        onChange={e => onChange(e.target.value)}
        onPressEnter={onApply}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
        ref={refSet}
      />
      <Space>
        <Button
          type="primary"
          onClick={onApply}
          icon={<SearchOutlined />}
          size="small"
          children='Search'
          style={{ width: 90 }}
        />
        <Button
          onClick={onReset}
          size="small"
          style={{ width: 90 }}
          children='Reset'
        />
      </Space>
    </div>
  )
}

export default ({
  request,
  newEntityPath,
  showCreateButton,
  title,
  columns,
  filterFields,
  rowKey,
  scroll,
  sortFields,
  selectFilter,
  filterIntersection
}: IndexListProps) => {

  const filter = selectFilter ? selectFilter.reduce((acc, { key, defaultValue }) => ({ ...acc, [key]: defaultValue }), {}) : {}

  const [filterState, setFilterState] = useState(filter)
  const [dataSource, setDataSource] = useState([])
  const [visibleEntities, setVisibleEntities] = useState([])

  const defaultFilterQuery = filterFields ?
    filterFields.reduce((acc, val) => ({ ...acc, [val.key]: "" }), {})
    :
    {}

  const [filterQuery, setFilterQuery] = useState(defaultFilterQuery)

  const { loading, error } = useQuery(request.query, {
    variables: { filter: filterState }, fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const result = request.transform(data[request.datakey])
      setVisibleEntities(result)
      setDataSource(result)
    }
  }
  )

  if (error) return (<span>Error.</span>)

  const applyFilter = (filterKey, filterValue) => {
    const data = filterIntersection ? visibleEntities : dataSource

    setVisibleEntities(
      data.filter(entity => {
        const fieldObj = filterFields?.find((el) => {
          return el.key === filterKey
        }
        )

        if (fieldObj) {
          const searchVal = filterValue.toUpperCase()

          if (fieldObj.path.includes('translations')) {
            const translations = get(entity, fieldObj.path)
            return Object.values(translations).find(value =>
              get(value, 'su')?.toUpperCase().includes(searchVal))
          } else if (fieldObj.path.includes('iata')) {
            return get(entity, fieldObj.path)?.toUpperCase().includes(searchVal)
          } else {
            return get(entity, fieldObj.path)?.toUpperCase().includes(searchVal)
          }
        }
        return false
      })

    )
  }

  const resetFilter = key => {
    setFilterQuery({ ...filterQuery, [key]: "" })
    setVisibleEntities(dataSource)
  }

  const createSearchFilter = (col) => {
    let input: Input

    return {
      ...col,
      filterDropdown: <Dropdown
        onApply={() => applyFilter(col?.key, filterQuery[`${col?.key}`])}
        onChange={(val) => setFilterQuery({ ...filterQuery, [`${col?.key}`]: val })}
        onReset={() => resetFilter(col?.key)}
        refSet={(ref) => input = ref}
      />,
      filterIcon: <SearchOutlined />,
      onFilterDropdownVisibleChange: visible => {
        if (visible)
          setTimeout(() => input.select(), 100);
      }
    }
  }

  const createSelectFilter = (col) => {
    const filter = selectFilter?.find(filter => {
      return filter.key === col.key
    })
    if (filter) {
      return {
        ...col,
        filters: filter.filterValues,
        defaultFilteredValue: filter.defaultValue
      }
    }
  }

  const tableColumns = columns
    .filter(col => col.hidden !== true)
    .map(col => {

      if (filterFields?.map(field => field.key)?.includes(`${col?.key}`)) {
        return createSearchFilter(col)
      }
      if (selectFilter?.map(field => field.key)?.includes(`${col?.key}`)) {
        return createSelectFilter(col)
      }
      return col

    })
    .map(col => {
      const sortField = sortFields?.find(field => field.key === col?.key)

      const defaultSorter = (a, b) => {
        return a === b ? 0 : (a < b ? -1 : 1)
      }

      return (
        sortField ?
          {
            ...col,
            sorter: (rowA, rowB) => (sortField.sorter || defaultSorter)(rowA[`${col?.key}`], rowB[`${col?.key}`]),
            sortDirections: sortField.directions,
            defaultSortOrder: sortField.default
          } : col
      )
    })

  return (
    <Table
      loading={loading}
      bordered={true}
      title={() => <ListTitle newRoute={newEntityPath} showCreateButton={showCreateButton} title={title} />}
      dataSource={visibleEntities}
      columns={tableColumns}
      onChange={(pagination, filters) => {
        setFilterState({ types: filters.types })
      }}
      pagination={{ defaultPageSize: 50 }}
      rowKey={rowKey}
      scroll={scroll}
    />
  )
}
