import React from 'react'
import {Form, Select} from 'antd'

interface LocationTypeInputProps {
    onChange: (e: any) => void, 
    disabled: boolean, 
    value: string,
    placeholder?: string,
    locationTypes: {"name": string}[]
}

export default ({value, placeholder, locationTypes, disabled, onChange}: LocationTypeInputProps) =>
  <Form.Item label='Location type'>
    <Select
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
    >
      {locationTypes.map(type =>
        <Select.Option value={type.name} key={type.name}>{type.name}</Select.Option>
      )}
    </Select>
  </Form.Item>
