import React, { useState } from 'react'
import { useMutation } from '../../mutation'
import { useQuery } from '../../query'
import { Card, Button, Alert, Spin } from 'antd'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import equal from 'fast-deep-equal'

import { UpdateMarketM } from './mutations'
import { MarketQ, LocalesCountriesQ } from './queries'

import Changelog from '../shared/changelog'

import CountryInput from '../shared/inputs/country'
import LocaleInput from '../shared/inputs/locale'
import TextInput from '../shared/inputs/text'

import { prepareComponents, prepareMutationInput, processErrors } from "../../utils"
import { getRule, getRules } from '../../access_rules'

interface UpdateMarketInput {
  code?: string,
  host?: string,
  localeId?: number,
  countryId?: number
}

export const schema = yup.object().shape({
  code: yup.string(),
  host: yup.string()
    // https://www.regexpal.com/?fam=93928
    .matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/, { message: "Hostname is not valid" }),
  localeId: yup.number(),
  countryId: yup.number()
})

export default (props) => {
  const id = parseInt(props.match.params.id)
  const history = useHistory()
  const [activeTab, setActiveTAb] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateMarketInput>({})

  const [updateMarket, { data: mutationData }] = useMutation(UpdateMarketM, setErrors)
  const { data: marketData, error, loading } = useQuery(MarketQ, { variables: { id }, fetchPolicy: 'network-only' })

  if (error) return (<Alert type='error' message='Market fetching error' />)
  if (loading) return (<Spin size='large' />)
  if (marketData.market === undefined) return (<></>)

  const { market: { locale, host, code, country } } = marketData

  const defaultInput = { code, host, localeId: locale.id, countryId: country.id }

  if (Object.keys(input).length === 0) setInput(defaultInput)

  if (mutationData) history.push(`/markets`)

  const performMutation = () =>
    schema.validate(input)
      .then(_ => {
        const mutationInput = prepareMutationInput(defaultInput, input)
        updateMarket({ variables: { id, input: mutationInput } })
      }, e => processErrors(e, setErrors))

  const accessRules = getRules();
  const components = {
    changelog: [
      {
        component: <Changelog entity='MARKET' entityId={id} />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ]
  }

  return (
    <>
      <Card
        loading={loading}
        tabList={[
          { key: 'main', tab: 'Main' },
          { key: 'changelog', tab: 'Changelog' }
        ]}
        onTabChange={key => setActiveTAb(key)}
        className='form'
      >
        {errors.map(e => <Alert type='error' message={e} key={e} />)}

        {
          {
            main: <MainForm setInput={setInput} input={input} accessRules={accessRules} />,
            changelog: prepareComponents('changelog', components.changelog)
          }[activeTab]
        }

        {
          (activeTab !== 'changelog') &&
          <>
            <Button
              style={{ float: 'left' }}
              disabled={equal(input, defaultInput)}
              onClick={() => setInput(defaultInput)}
              children='Reset Changes'
            />

            <Button
              onClick={performMutation}
              style={{ float: 'right' }}
              type='primary'
              children='Update market'
            />
          </>
        }
      </Card>
    </>
  )
}

const MainForm = ({ setInput, input, accessRules }) => {
  const { data: inputsData, loading, error } = useQuery(LocalesCountriesQ)

  if (error) return (<Alert type='error' message='Error fetching inputs data' />)
  if (loading) return (<Spin size='large' />)

  const components = [
    {
      component: <TextInput
        label='Code'
        value={input.code}
        onChange={e => setInput({ ...input, code: e.target.value })}
      />,
      rule: getRule(accessRules, 'market', 'code')
    },
    {
      component: <TextInput
        label='Host'
        value={input.host}
        onChange={e => setInput({ ...input, host: e.target.value })}
      />,
      rule: getRule(accessRules, 'market', 'host')
    },
    {
      component: <CountryInput
        countries={inputsData.countries}
        disabled={loading}
        value={input.countryId}
        onChange={countryId => setInput({ ...input, countryId })}
      />,
      rule: getRule(accessRules, 'market', 'country')
    },
    {
      component: <LocaleInput
        value={input.localeId || -1}
        disabled={loading}
        onChange={localeId => setInput({ ...input, localeId })}
        locales={inputsData.locales}
      />,
      rule: getRule(accessRules, 'market', 'locale')
    }
  ]

  return (
    <>
      {
        prepareComponents('main-form', components)
      }
    </>
  )
}
