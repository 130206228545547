import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '../../query'
import { FilterLocationQuery, InputsLocationTypeQ } from './queries'
import { Translations } from '../../types'
import IndexList from '../shared/index_list'
import ActiveBadge from '../shared/active_badge'
import { prepareComponents } from '../../utils'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../access_rules'
import { Alert } from 'antd'

interface ListLocationsResponse {
  id: string,
  isActive: boolean,
  type: string,
  slug: string,
  translations: Translations,
  iata?: string
}

interface ListLocationsTableRow {
  id: string,
  isActive: boolean,
  type: string,
  slug: string,
  iata?: string
}

const responseToTable = (data: Array<ListLocationsResponse>): Array<ListLocationsTableRow> => {
  return data.map(({ id, isActive, type, slug, iata, translations }) => ({
    id, isActive, type, slug, iata, translations
  }))
}

const responseToFilterValues = (data) => {
  return data.map(value => {
    return { text: value.name, value: value.name }
  })
}

export default (props: any) => {
  const { data, error } = useQuery(InputsLocationTypeQ, { fetchPolicy: 'network-only' })
  if (error) return (<span>Error</span>)

  const locationTypes = responseToFilterValues(data && data.__type.enumValues ? data.__type.enumValues : [])
  const accessRules = getRules()
  const createdLocationId = props.location?.state?.createdId
  const columns = [
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 80,
      render: (isActive: boolean) =>
        <div style={{ textAlign: 'center' }}>
          <ActiveBadge
            isActive={isActive}
            activeText='active'
            inactiveText='inactive'
          />
        </div>,
      hidden: notAllowedToShow(accessRules, 'location', 'isActive')
    },
    {
      title: "type",
      dataIndex: "type",
      key: "types",
      width: 140,
      hidden: notAllowedToShow(accessRules, 'location', 'type')
    },
    {
      title: "name",
      dataIndex: "translations",
      key: "name",
      render: (translations: Translations) =>
        translations ?
          <span>{translations.en?.su}</span>
          :
          <span>no translation </span>,
      hidden: notAllowedToShow(accessRules, 'location', 'translations')
    },
    {
      title: "slug",
      dataIndex: "slug",
      key: "slug",
      hidden: notAllowedToShow(accessRules, 'location', 'slug')
    },
    {
      title: "iata",
      dataIndex: "iata",
      key: "iata",
      width: 70,
      hidden: notAllowedToShow(accessRules, 'location', 'iata'),
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: 60,
      render: (id: string) =>
        <span><Link to={`/locations/${id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'location')
    }
  ]

  return (
    <> 
      {createdLocationId && <Alert closable type='success' message={`Location with id ${createdLocationId} created successfully`}></Alert>}
      {
        prepareComponents('locations-list', [{
          component: <IndexList
            request={{ query: FilterLocationQuery, datakey: 'locations', transform: responseToTable }}
            columns={columns}
            title='Locations'
            rowKey='id'
            newEntityPath='/locations/new'
            showCreateButton={accessRules['__all_mutations']}
            filterFields={[
              { key: 'name', path: 'translations' },
            ]}
            selectFilter={[
              { key: 'types', defaultValue: ['NATIONAL_PARK'], filterValues: locationTypes }
            ]
            }
            sortFields={[
              { key: 'isActive' }
            ]}
          />,
          rule: getRule(accessRules, 'query', 'locations')
        }])
      }
    </>
  )
}
