import gql from 'graphql-tag'

export const CreateCountryM = gql`
  mutation CreateCountry($input: CreateCountryInput!) {
    createCountry(input: $input) { id }
  }
`

export const UpdateCountryM = gql`
  mutation UpdateCountry($input: UpdateCountryInput!, $id: Int!){
    updateCountry(input: $input, id: $id) { id iata }
  }
`

export const UpdateContentTextsM = gql`
  mutation UpdateCountryContentTexts($input: UpdateCountryContentTextsInput!, $countryId: Int!){
    updateCountryContentTexts(input: $input, countryId: $countryId) { __typename }
  }
`
