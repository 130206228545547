import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { CreateLanguageM } from './mutations'
import { useHistory } from 'react-router-dom'
import { Form, Button, Input, Card, Alert } from 'antd'

import * as yup from 'yup'
import '../../shared/form.css'

import TranslationsInput from '../../shared/translations'
import { processErrors, preprocessInputTranslations } from '../../../utils'
import { Translations } from '../../../types'

interface CreateLanguageInput {
  code: string,
  translations: Translations
}

const schema = yup.object().shape({
  code: yup.string().required().length(2),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required() }).required()
  }).required()
})

const defaultInput = { code: '', translations: {} }

export default () => {
  let history = useHistory()

  const [input, setInput] = useState<CreateLanguageInput>(defaultInput)
  const [errors, setErrors] = useState<Array<string>>([])
  const [activeTab, setActiveTab] = useState<string>('main')
  const [createLanguage, { data: mutationData }] = useMutation(CreateLanguageM, setErrors)

  const performMutation = () =>
    schema.validate(input)
      .then(_ => createLanguage({ variables: { input: preprocessInputTranslations(input) } }),
        e => processErrors(e, setErrors))

  if (mutationData) history.push({
    pathname: `/languages`,
    state: { createdId: mutationData.createLanguage.id }
  })

  return (

    <Card
      className='form'
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' }
      ]}
      onTabChange={key => setActiveTab(key)}
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
          />,
          translations: <TranslationsInput
            translations={input.translations}
            setTranslations={translations => setInput({ ...input, translations })}
          />
        }[activeTab]
      }

      <Button
        style={{ float: 'left' }}
        onClick={() => setInput(defaultInput)}
        children='Reset Changes'
      />

      <Button
        disabled={false}
        type='primary'
        htmlType='submit'
        style={{ float: 'right' }}
        children='Save'
        onClick={performMutation}
      />

    </Card>
  )
}

const MainForm = ({ setInput, input }) =>
  <>
    <Form.Item label="Code">
      <Input
        placeholder='code'
        required
        value={input.code}
        onChange={e => setInput({ ...input, code: e.target.value })}
        style={{ width: 188 }}
      />
    </Form.Item>
  </>
