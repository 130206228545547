import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'antd'

interface ListTitleProps {
  title: string,
  showResetButton?: boolean | false,
  showCreateButton?: boolean,
  newRoute: string
}

export default (props: ListTitleProps): any =>
  <div>
    <Row justify='space-between'>
      <Col span={12}><h1>{props.title}</h1></Col>
      <Col span={12} style={{ marginTop: '5px' }}>
        <Row justify='end'>
          {props.showResetButton && <Button style={{ marginRight: '15px' }} children='Reset filters' />}

          {
            props.showCreateButton && <Link to={props.newRoute}>
              <Button type='primary' children='Create' />
            </Link>
          }

        </Row>
      </Col>
    </Row>
  </div>
