import { useQuery as apolloUseQuery } from '@apollo/react-hooks';
import { DocumentNode, SelectionNode, visit } from 'graphql';
import { QueryResult } from '@apollo/react-common';
import { getRules } from './access_rules';

export function useQuery(query: DocumentNode, options?: any): QueryResult {
  const accessRules = getRules();
  let preparedQuery = (accessRules['__all_queries'] !== true) ?
    filterFieldsFromQuery(query, (fieldName, parentName) => {
      return (accessRules[parentName] !== undefined) && (accessRules[parentName][fieldName] === "show" || accessRules[parentName][fieldName] === "edit")
    }) : query;
  return apolloUseQuery(preparedQuery, options)
}

function filterFieldsFromQuery(query: DocumentNode, filterFunction: (fieldName: string, parentName: string) => boolean): DocumentNode {
  return visit(query, {
    SelectionSet(node, _key, parent, _path, _ancestors) {
      let parentName = ""
      let selections: Array<SelectionNode> = []

      if (parent && 'kind' in parent) {
        switch (parent.kind) {
          case 'OperationDefinition':
            parentName = parent.operation
            break;

          case 'Field':
            parentName = parent.name.value
            break;

          case 'InlineFragment':
            parentName = (parent.typeCondition?.name.value || '').toLowerCase()
            break;

          default:
            break;
        }
      }

      node.selections.forEach((selectionNode) => {
        if (selectionNode.kind === 'Field') {
          const fieldName = selectionNode.name.value
          if (filterFunction(fieldName, parentName)) {
            selections.push(selectionNode)
          }
        } else {
          // "FragmentSpread" and "InlineFragment" cases
          selections.push(selectionNode)
        }
      })

      if (node.selections.length !== selections.length) {
        node.selections = selections
        return node
      } else {
        return undefined
      }
    }
  })
}
