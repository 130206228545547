import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useQuery } from '../../../query'
import { useMutation } from '../../../mutation'
import { Alert, Spin, Card, Button, List, Select, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import { MetropolisQ, AllAirportsQ } from './queries'
import { UpdateAirportM } from '../airports/mutations'

import { Translations } from '../../../types'
import { getRule, getRules } from '../../../access_rules'
import { processErrors, prepareComponents } from '../../../utils'

interface CutAirport {
  id: number,
  translations: Translations,
  iata: string
}
export default (props: any) => {
  const metropolisId = parseInt(props.match.params.id)

  const [chosenAirports, setChosenAirports] = useState<Array<CutAirport>>([])
  const [metropolisAirports, setMetropolisAirports] = useState<Array<CutAirport>>([])
  const [selectOpen, setSelectOpen] = useState<boolean>(false)
  const [searchVal, setSearchVal] = useState<string>('')
  const [, setErrors] = useState<Array<string>>([])

  const { data, error, loading } = useQuery(MetropolisQ,
    {
      variables: { id: metropolisId },
      onCompleted: data => setMetropolisAirports([...metropolisAirports, ...(data.metropoly?.airports || [])]),
      fetchPolicy: 'network-only'
    }
  )
  const [updateAirport] = useMutation(UpdateAirportM, setErrors)
  const [fetchAllAirports, { loading: airportsLoading, data: airportsList }] = useLazyQuery(AllAirportsQ)

  if (error) return (<Alert message='Fetching error' type='error' />)
  if (loading) return (<Spin size='large' />)

  const saveAirport = (airportId: number) => updateAirport({
    variables: {
      id: airportId, input: { metropolyId: metropolisId }
    },
  }).then((_data) => {
    setMetropolisAirports([
      airportsList.airports.find(ap => ap.id === airportId),
      ...metropolisAirports
    ])
    rmChosenAirport(airportId)
    setSearchVal('')
  }, e => processErrors(e, setErrors))

  const deleteAirport = (airportId: number) => updateAirport({
    variables: { id: airportId, input: { metropolyId: null } }
  }).then(_data => setMetropolisAirports(metropolisAirports.filter(ap => ap.id !== airportId)))

  const chooseAirport = (id: number): void => {
    const newAirport = airportsList.airports.find(ap => ap.id === id)
    setChosenAirports([newAirport, ...chosenAirports])
  }

  const rmChosenAirport = (id: number): void => setChosenAirports(chosenAirports.filter(a => a.id !== id))

  const { metropoly } = data

  const accessRules = getRules();

  const cardComponent =
    <Card
      style={{ marginLeft: '15%', marginRight: '15%' }}
    >
      {
        <List
          header={<div style={{ display: 'flex', justifyContent: 'center' }}>Airports of {metropoly?.iata} metropolis</div>}
          dataSource={metropolisAirports}
          bordered
          renderItem={(item: CutAirport) =>
            <List.Item>
              <span>{item?.translations?.en?.su}(<Link to={`/airports/${item.id}`}>{item.iata}</Link>)</span>

              <Popconfirm
                title='Are you sure? It will change search config.'
                onConfirm={() => deleteAirport(item.id)}
              >
                <Button
                  children='X'
                  size='small'
                  danger
                />
              </Popconfirm>
            </List.Item>
          }
        />
      }

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={{ marginTop: '3%' }}
          children={selectOpen ? 'Hide' : 'Add airports'}
          type='primary'
          onClick={() => {
            setSelectOpen(!selectOpen)
            fetchAllAirports()
          }}
        />
      </div>


      {airportsLoading && <Spin size={'large'} />}

      {
        selectOpen && airportsList &&
        <>
          <Select
            style={{ width: '70%', marginLeft: '15%', marginTop: '4%' }}
            defaultValue={-1}
            filterOption={false}
            showSearch
            onSearch={val => setSearchVal(val)}
            onChange={airportId => chooseAirport(airportId)}
          >
            <Select.Option value={-1} key='null'>Not Selected</Select.Option>
            {
              airportsList.airports
                .filter(ap => ap.iata.includes(searchVal.toUpperCase()))
                .map(ap => <Select.Option value={ap.id} key={ap.id}>{ap.iata}</Select.Option>)
            }
          </Select>
        </>
      }

      {
        chosenAirports.length > 0 &&
        <List
          dataSource={chosenAirports}
          style={{ marginTop: '3%' }}
          renderItem={item =>
            <List.Item style={{ display: 'flex', justifyContent: 'center', width: '80%' }}>
              <span>{item.iata}</span>
              <Button
                children='Save'
                style={{ marginLeft: '8%' }}
                onClick={() => saveAirport(item.id)}
              />
              <Button
                children='X'
                type={'primary'}
                danger
                onClick={() => rmChosenAirport(item.id)}
                style={{ marginLeft: '8%' }}
              />
            </List.Item>
          }
        />
      }
    </Card>

  return (
    <>
      {
        prepareComponents('metropolises-edit-form', [
          {
            component: cardComponent,
            rule: getRule(accessRules, 'metropoly', 'airports')
          }
        ])
      }
    </>
  )
}
