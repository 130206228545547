import gql from 'graphql-tag'

export const ListLanguagesQ = gql`
  query {
      languages {
          id 
          code
          translations
      }
  }
`

export const LanguageQ = gql`
  query GetLanguage($id: Int!){
    language(id: $id){ code translations }
  }
`  
