import gql from 'graphql-tag'

export const CreateAirlineM = gql`
  mutation CreateAirline($input: CreateAirlineInput!){
    createAirline(input: $input) { id }
  }
`

export const UpdateAirlineM = gql`
  mutation UpdateAirline($input: UpdateAirlineInput!, $id: Int!){
    updateAirline(input: $input, id: $id) { id iata }
  }
`
