import React from 'react'
import { ListAirportsQ } from './queries'
import { Link } from 'react-router-dom'
import { City, Country, Translations } from '../../../types';
import IndexList from '../../shared/index_list'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../../access_rules';
import { prepareComponents } from '../../../utils';
import { Alert } from 'antd';

interface ListAirportsResponse {
  id: number,
  iata: string,
  type: string,
  translations: Translations,
  city: City,
}
interface AirportTableRow {
  id: number,
  iata: string,
  type: string,
  translations: Translations,
  city: City,
  country?: Country
}

const responseToAirportTableRow = (data: Array<ListAirportsResponse>): Array<AirportTableRow> => {
  return data.map(({ iata, type, id, translations, city }) => (
    {
      iata,
      id,
      type,
      city,
      translations,
      country: city!.country
    }))
}

export default (props: any) => {
  const updatedAirportIata = props.location?.state?.mutationData?.updateAirport?.iata
  const createdAirportId = props.location?.state?.createdId
  
  const accessRules = getRules()

  const columns: Array<object> = [
    {
      title: "Iata",
      dataIndex: "iata",
      name: "iata",
      key: 'iata',
      hidden: notAllowedToShow(accessRules, 'airport', 'iata')
    },
    {
      title: "Type",
      dataIndex: "type",
      name: "type",
      key: 'type',
      hidden: notAllowedToShow(accessRules, 'airport', 'type')
    },
    {
      title: "Name",
      dataIndex: "translations",
      name: "name",
      key: 'name',
      hidden: notAllowedToShow(accessRules, 'airport', 'translations'),
      render: (translations: Translations) =>
        <span>{translations.en.su}</span>
    },
    {
      title: "Country",
      dataIndex: "country",
      name: "country",
      key: 'country',
      hidden: notAllowedToShow(accessRules, 'airport', 'country'),
      render: (country: Country) =>
        country && country.translations ?
          (
            <Link to={`/countries/${country.id}`}>{country.translations.en.su}</Link>
          ) :
          (
            <span>No country</span>
          )
    },
    {
      title: "City",
      dataIndex: "city",
      key: 'city',
      name: "city",
      hidden: notAllowedToShow(accessRules, 'airport', 'city'),
      render: (city: City) =>
        city && city.translations ?
          (
            <Link to={`/cities/${city.id}`}>{city.translations.en.su}</Link>
          ) : (
            <span>No City</span>
          )
    },
    {
      title: "Actions",
      width: 60,
      dataIndex: 'id',
      render: (id: number) =>
        <span><Link to={`/airports/${id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'airport')
    }
  ]

  return (
    <>
      {createdAirportId && <Alert closable type='success' message={`Airport with id ${createdAirportId} created successfully`}></Alert>}
      {updatedAirportIata && <Alert closable type='success' message={`Airport ${updatedAirportIata} updated successfully`}></Alert>}
      {
        prepareComponents('airports-list', [{
          component: <IndexList
            request={{ query: ListAirportsQ, datakey: 'airports', transform: responseToAirportTableRow }}
            title="Airports"
            newEntityPath='/airports/new'
            showCreateButton={accessRules['__all_mutations']}
            columns={columns}
            rowKey='id'
            filterFields={[
              { key: 'iata', path: 'iata' },
              { key: 'city', path: 'city.translations' },
              { key: 'name', path: 'translations' },
              { key: 'country', path: 'country.translations' }
            ]}
          />,
          rule: getRule(accessRules, 'query', 'airports')
        }])
      }
    </>
  )
}
