import React, { useState } from 'react'
import { useMutation } from '../../mutation'
import { useQuery } from '../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin } from 'antd'
import equal from 'fast-deep-equal'

import { CreateLocationM } from './mutations'
import { Translations } from '../../types'
import { InputsLocationTypeQ } from './queries'
import * as yup from 'yup'

import LocationTypeInput from './inputs/location_type'
import TranslationsInput from '../shared/translations'
import TextInput from '../shared/inputs/text'
import BoolInput from '../shared/inputs/bool'

import '../shared/form.css'
import { preprocessInputTranslations, processErrors } from '../../utils'

interface CreateLocationInput {
  isActive: boolean,
  type: string,
  slug: string,
  translations: Translations
  iata?: string,
}

export const validationSchema = yup.object().shape({
  iata: yup.string().matches(/^[A-Z]{3}|[А-Я]{3}$/, {
    message: 'iata must be 3 characters long and contain only A-Z or А-Я letters', excludeEmptyString: true
  }).nullable(),
  isActive: yup.boolean().required(),
  slug: yup.string().required(),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation must be set') })
  }),
  type: yup.string().required()
})

const defaultInput = { isActive: false, type: 'NATIONAL_PARK', slug: '', translations: {} }

export default () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<CreateLocationInput>(defaultInput)
  const [createLocation, { data: mutationData }] = useMutation(CreateLocationM, setErrors)

  const performMutation = () =>
    validationSchema.validate(input)
      .then(_ => createLocation({ variables: { input: preprocessInputTranslations(input) } }),
        e => processErrors(e, setErrors))

  if (mutationData) history.push({
    pathname: `/locations`,
    state: { createdId: mutationData.createLocation.id }
  })

  return (
    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' }
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
          />,
          translations: <TranslationsInput
            translations={input.translations}
            setTranslations={translations => setInput({ ...input, translations })}
          />
        }[activeTab]
      }

      <Button
        style={{ float: 'left' }}
        disabled={equal(input, defaultInput)}
        onClick={() => setInput(defaultInput)}
        children='Reset Changes'
      />

      <Button
        style={{ float: 'right' }}
        onClick={performMutation}
        type='primary'
        children='Create location'
      />
    </Card>
  )
}
const MainForm = ({ input, setInput }) => {
  const { loading, error: qError, data: inputsData } = useQuery(InputsLocationTypeQ)

  if (loading) return (<Spin />)
  if (qError) return (<Alert message='Location types fetching error' />)

  return (
    <>
      <BoolInput
        label='is active'
        checked={input.isActive}
        onChange={val => setInput({ ...input, isActive: val })}
      />

      <LocationTypeInput
        value={input.type}
        disabled={loading}
        placeholder='location type'
        onChange={type => setInput({ ...input, type })}
        locationTypes={inputsData.__type.enumValues}
      />

      <TextInput
        label='slug'
        placeholder='slug'
        value={input.slug}
        onChange={e => setInput({ ...input, slug: e.target.value })}
      />

      <TextInput
        label='iata'
        value={input.iata}
        placeholder='iata (3 characters, A-Z/А-Я)'
        onChange={e => setInput({ ...input, iata: e.target.value })}
      />
    </>
  )
}
