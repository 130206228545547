import gql from 'graphql-tag'

export const CreateLocationM = gql`
  mutation CreateLocation($input: CreateLocationInput!){
    createLocation(input: $input) { id }
  }
`

export const UpdateLocationM = gql`
  mutation UpdateLocation($input: UpdateLocationInput!, $id: ID!){
    updateLocation(input: $input, id: $id) { id }
  }
`
