import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { Button, Alert } from 'antd'
import React, { useState } from 'react'
import equal from 'fast-deep-equal'
import { useHistory } from 'react-router-dom'

import CountryContentTextsInput from '../../shared/inputs/country_content_texts'
import { UpdateContentTextsM } from './mutations'
import { ContentTextsQ } from './queries'
import { prepareMutationInput, processErrors } from '../../../utils';

interface ContentTextsProps {
  countryId: string | number
}

interface UpdateContentTextsInput {
  visaContent?: string,
  climateContent?: string,
  timezoneContent?: string,
  moneyContent?: string,
  rentContent?: string
}

const prepareTextsObjectFromGQL = (gqlResultObject) => {
  const countryPage = gqlResultObject.contentPages.countryPage
  return {
    visaContent: countryPage.visaContent?.ru,
    climateContent: countryPage.climateContent?.ru,
    timezoneContent: countryPage.timezoneContent?.ru,
    moneyContent: countryPage.moneyContent?.ru,
    rentContent: countryPage.rentContent?.ru
  }
}

export default ({ countryId }: ContentTextsProps) => {
  const history = useHistory()

  const [input, setInput] = useState<UpdateContentTextsInput>({})
  const [inputFilledFromBackend, setInputFilledFromBackend] = useState<Boolean>(false)
  const [errors, setErrors] = useState<Array<string>>([])

  const [updateContentTexts, { data: mutationData }] = useMutation(UpdateContentTextsM, setErrors)
  const { error, data, loading } = useQuery(ContentTextsQ, { variables: { id: countryId, market: 'ru' } })

  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>

  const backendValues = prepareTextsObjectFromGQL(data)

  if (!inputFilledFromBackend) {
    setInput(backendValues)
    setInputFilledFromBackend(true)
  }

  if (mutationData) history.push({
    pathname: `/countries`,
    state: { mutationData: mutationData }
  })

  const performMutation = () => new Promise(() => {
    const mutationInput = prepareMutationInput(backendValues, input);
    updateContentTexts({ variables: { input: mutationInput, countryId } })
  }).catch(obj => processErrors(obj, setErrors));

  return (
    <div>
      {errors.map(e => <Alert type='error' message={e} key={e} />)}
      <CountryContentTextsInput
        onChange={contentTexts => setInput({ ...input, ...contentTexts })}
        contentTexts={input}
      />

      <Button
        style={{ float: 'left' }}
        disabled={equal(input, backendValues)}
        onClick={() => setInput(backendValues)}
        children='Reset Content Texts'
      />
      <Button
        onClick={performMutation}
        style={{ float: 'right' }}
        type='primary'
        children='Update Content Texts'
      />
    </div>
  )
}
