import gql from 'graphql-tag'

export const ListCitiesQ = gql`
  query {
      cities {
          id
          iata
          translations(filter: {locales: ["en", "ru"]})

          country {
              id
              iata
              translations(filter: {locales: ["en", "ru"]})
          }
      }
  }
`

export const CountriesGlossaryQ = gql`
  query {
      countries {id iata translations(filter: {locales: ["en", "ru"]})}
      glossary { timeZones }
  }
`

export const CityQ = gql`
  query GetCity($id: Int!){
    city(id: $id){
      id
      iata
      translations
      seoTranslations
      population
      timeZone: timezone { name }
      coordinates
      slugEn: slug(marketCode: "us")
      slugRu: slug(marketCode: "ru")

      country { id iata translations }
    }
  }
`
