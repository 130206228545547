import React from 'react'
import {Form, Switch} from 'antd'
import LatestChange, {Data as LatestChangeData} from "../latest_change";

interface BoolInputProps {
  onChange: (_) => void
  checked: boolean
  label: string
  latestChange?: LatestChangeData
}

export default ({onChange, label, checked, latestChange}: BoolInputProps) =>
  <Form.Item label={label}>
    <Switch
      onChange={onChange}
      checked={checked}
    />
    <LatestChange data={latestChange}/>
  </Form.Item>
