import isEmpty from 'lodash.isempty'
import { GQLError, Translations } from './types'
import React, { ReactElement } from 'react'
import equal from 'fast-deep-equal'

interface ComponentWithRule {
  component: ReactElement,
  rule: 'edit' | 'show' | 'disallow'
}

export const processErrors = (
  errorObject: any,
  callback: (_: any) => void): void => {
  if (errorObject.errors) {
    callback(errorObject.errors)
  } else if (errorObject.graphQLErrors) {
    callback(prepareGQLErrors(errorObject.graphQLErrors))
  }
}

const prepareGQLErrors = (graphQLErrors: Array<GQLError>) => {
  const errors = graphQLErrors.reduce((acc: Array<string>, { details, message }) => {
    if (!details) {
      if (message) return [...acc, message];
      return acc
    }

    const details_errors = Object.keys(details).reduce((acc: Array<string>, key: string) => {
      return [...acc, `${key} ${details[key]}`];
    }, []);
    return [...acc, ...details_errors];
  }, []);

  return errors;
}

export const preprocessInputTranslation = translation => JSON.stringify(trimTranslationsValues(translation))

export const preprocessJSONInputsGently = input => {
  return Object.entries(input).reduce((newObj, [key, value]) => {
    switch (key) {
      case 'translations':
        newObj[key] = preprocessInputTranslation(value);
        break;

      case 'seoTranslations':
        newObj[key] = preprocessInputTranslation(value);
        break;

      case 'contentTexts':
        newObj[key] = JSON.stringify(value || {});
        break;

      case 'website':
        newObj[key] = JSON.stringify(value || {});
        break;

      default:
        newObj[key] = value;
    }
    return newObj;
  }, {});
}

export const preprocessInputTranslations = input => {
  let resultObject = { ...input, translations: preprocessInputTranslation(input.translations) }

  if (!!input['seoTranslations']) {
    resultObject['seoTranslations'] = preprocessInputTranslation(input.seoTranslations)
  }

  return resultObject;
}

export function prepareComponents(formKey: string, componentsWithRules: Array<ComponentWithRule>) {
  return componentsWithRules.reduce((accumulator: Array<ReactElement>, componentWithRule, index) => {
    const element = prepareComponent(componentWithRule, `${formKey}-${index}`);
    if (element === null) {
      return accumulator;
    } else {
      return accumulator.concat([element])
    }
  }, []);
}

function prepareComponent(componentWithRule: ComponentWithRule, key: string) {
  const component = componentWithRule.component;
  if (componentWithRule.rule === 'edit') return React.cloneElement(component, { key: key })
  if (componentWithRule.rule === 'show') return React.cloneElement(component, { key: key, disabled: true });
  return null;
}

export function prepareMutationInput(serverData: any, inputState: any) {
  return Object.entries(inputState).reduce((newObject, [key, value]) => {
    if (!equal(serverData[key], value)) newObject[key] = value;
    return newObject;
  }, {});
}

export const translationsContainString = (translations: Translations, str: string) =>
  Object.values(translations).some(obj => obj['su'] && obj['su'].includes(capitalize(str)))

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

const trimTranslationsValues = translations =>
  Object.keys(translations).reduce((acc, locale) => {
    const objWithCases = translations[locale]
    return objWithCases.su.trim() === "" ? acc : ({ ...acc, [locale]: trimAndSkipEmptyObjectValues(objWithCases) })
  }, {})

const trimAndSkipEmptyObjectValues = obj =>
  Object.keys(obj).reduce((acc, key) => (
    (isEmpty(obj[key]) || obj[key].trim() === "") ? acc : { ...acc, [key]: obj[key].trim() }
  ), {})

export function snakeToCamel(str: string): string {
  const parts = str.split('_')
  if (parts.length === 1)
    return parts[0]

  return parts[0] + parts.slice(1).map(capitalize).join('')
}
