import React, { useState } from 'react'
import { Card, Alert, Spin, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import * as yup from 'yup'
import equal from 'fast-deep-equal'
import { UpdateLocaleM } from './mutations'
import { LocaleQ, LanguagesQ } from './queries'

import Changelog from '../../shared/changelog'

import LanguageInput from '../../shared/inputs/language'
import TextInput from '../../shared/inputs/text'

import { prepareComponents, prepareMutationInput, processErrors } from '../../../utils'
import { getRules, getRule } from '../../../access_rules'

interface UpdateLocaleInput {
  name?: string,
  hasNameCases?: boolean,
  nameCases?: string[],
  languageId?: number
}

export const schema = yup.object().shape({
  name: yup.string().required(),
  languageId: yup.number().positive().nullable()
})

export default (props) => {
  const id = parseInt(props.match.params.id)
  const history = useHistory()
  const [activeTab, setActiveTAb] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateLocaleInput>({})

  const [updateLocale, { data: mutationData }] = useMutation(UpdateLocaleM, setErrors)
  const { data: localeData, error, loading } = useQuery(LocaleQ, { variables: { id }, fetchPolicy: 'network-only' })

  if (error) return (<Alert type='error' message='Locale fetching error' />)
  if (loading) return (<Spin size='large' />)

  if (localeData.locale === undefined) return (<></>)
  const { locale: { name, language, nameCases, hasNameCases } } = localeData

  const defaultInput = {
    name,
    nameCases,
    hasNameCases,
    languageId: language?.id || null
  }

  if (Object.keys(input).length === 0) setInput(defaultInput)

  if (mutationData) history.push(`/locales`)

  const performMutation = () =>
    schema.validate(input)
      .then(_ => {
        const mutationInput = prepareMutationInput(defaultInput, input)
        updateLocale({ variables: { input: mutationInput, id } })
      }, e => processErrors(e, setErrors))

  const accessRules = getRules()
  const components = {
    changelog: [
      {
        component: <Changelog entityId={id} entity='LOCALE' />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ]
  }

  return (
    <>
      <Card
        tabList={[
          { key: 'main', tab: 'Main' },
          { key: 'changelog', tab: 'Changelog' }
        ]}
        onTabChange={key => setActiveTAb(key)}
        className='form'
      >
        {errors.map(e => <Alert type='error' message={e} key={e} />)}

        {
          {
            main: <MainForm setInput={setInput} input={input} accessRules={accessRules} />,
            changelog: prepareComponents('changelog', components.changelog)
          }[activeTab]
        }

        {
          (activeTab !== 'changelog') &&
          <>
            <Button
              style={{ float: 'left' }}
              disabled={equal(input, defaultInput)}
              onClick={() => setInput(defaultInput)}
              children='Reset Changes'
            />

            <Button
              onClick={performMutation}
              style={{ float: 'right' }}
              type='primary'
              children='Update locale'
            />
          </>
        }

      </Card>
    </>
  )
}

const MainForm = ({ input, setInput, accessRules }) => {
  const { data: inputsData, error, loading } = useQuery(LanguagesQ)

  if (error) return (<Alert type='error' message='Market fetching error' />)
  if (loading) return (<Spin size='large' />)

  const inputComponents = [
    {
      component: <TextInput
        label='Name'
        onChange={e => setInput({ ...input, name: e.target.value })}
        value={input.name}
      />,
      rule: getRule(accessRules, 'locale', 'name')
    },
    {
      component: <LanguageInput
        disabled={loading}
        value={input.languageId}
        onChange={languageId => setInput({ ...input, languageId })}
        languages={inputsData.languages}
      />,
      rule: getRule(accessRules, 'locale', 'language')
    }
  ]

  return (
    <>
      {
        prepareComponents('main-form', inputComponents)
      }
    </>
  )
}
