import gql from "graphql-tag";

export const ListLocationsQ = gql`
  query {
    locations {
      id
      isActive
      type
      slug
      translations(filter: { locales: ["en", "ru"] })
      iata
    }
  }
`;

export const LocationQ = gql`
  query GetLocation($id: ID!) {
    location(id: $id) {
      id
      isActive
      type
      slug
      translations
      iata
    }
  }
`;

export const InputsLocationTypeQ = gql`
  query {
    __type(name: "LocationType") {
      enumValues {
        name
      }
    }
  }
`;

export const FilterLocationQuery = gql`
  query GetFilteredLocations($filter: LocationFilter) {
    locations(filter: $filter) {
      id
      isActive
      type
      slug
      translations
      iata
    }
  }
`;
