import React from 'react'
import { Link } from 'react-router-dom'
import { ListCitiesQ } from './queries'
import { Translations, Country, City } from '../../../types'
import IndexList from '../../shared/index_list'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../../access_rules'
import { prepareComponents } from '../../../utils'
import { Alert } from 'antd'

interface CitiesListResponse {
  id: number,
  iata: string,
  translations: Translations,
  country: Country
}

interface CitiesListTableRow {
  iata: string,
  city: City,
  country: Country
}

const responseToTableRow = (data: Array<CitiesListResponse>): Array<CitiesListTableRow> => {
  return data.map(({ id, iata, translations, country }) => (
    { id, iata, city: { id, iata, translations }, country }
  ))
}

export default (props: any) => {
  const createdCityId = props.location?.state?.createdId
  const updatedCityIata = props.location?.state?.mutationData?.updateCity?.iata

  const accessRules = getRules()

  const columns = [
    {
      title: "Iata",
      dataIndex: "iata",
      key: "iata",
      hidden: notAllowedToShow(accessRules, 'city', 'iata')
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      hidden: notAllowedToShow(accessRules, 'city', 'translations'),
      render: (city: City) =>
        city && city.translations ?
          <Link to={`/cities/${city.id}`}>{city.translations.en.su}</Link>
          :
          <span>No city translation</span>
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      hidden: notAllowedToShow(accessRules, 'city', 'country'),
      render: (country: Country) =>
        country && country.translations ?
          <Link to={`/countries/${country.id}`}>{country.translations.en.su}</Link>
          :
          <span>no country translation</span>
    },
    {
      title: "Actions",
      dataIndex: "city",
      width: 60,
      render: (city: City) =>
        <span><Link to={`/cities/${city.id}`}>Edit</Link></span>,
      key: "actions",
      hidden: notAllowedToEdit(accessRules, 'city')
    }
  ]

  return (
    <>
      {createdCityId && <Alert closable type='success' message={`City with id ${createdCityId} created successfully`}></Alert>}
      {updatedCityIata && <Alert closable type='success' message={`City ${updatedCityIata} updated succeffully`}></Alert>}
      {
        prepareComponents('cities-list', [{
          component: <IndexList
            request={{ query: ListCitiesQ, datakey: 'cities', transform: responseToTableRow }}
            columns={columns}
            title='Cities'
            rowKey='id'
            newEntityPath='/cities/new'
            showCreateButton={accessRules['__all_mutations']}
            filterFields={[
              { key: 'iata', path: 'iata' },
              { key: 'city', path: 'city.translations' },
              { key: 'country', path: 'country.translations' }
            ]}
          />,
          rule: getRule(accessRules, 'query', 'cities')
        }])
      }
    </>
  )
}
