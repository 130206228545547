import gql from "graphql-tag";

export const ListAirlinesQ = gql`
  query {
    airlines {
      id
      iata
      icao
      translations(filter: { locales: ["en", "ru"] })
      country {
        id
        translations(filter: { locales: ["en", "ru"] })
      }
    }
  }
`;

export const CountriesQ = gql`
  query {
    countries {
      id
      iata
      translations(filter: { locales: ["en", "ru"] })
    }
  }
`;

export const AirlineQ = gql`
  query GetAirline($id: Int!) {
    airline(id: $id) {
      id
      iata
      icao
      globalId
      isFlightable
      isLowcost
      translations
      seoTranslations
      address
      phones
      slugEn: slug(marketCode: "us")
      slugRu: slug(marketCode: "ru")
      brandColor
      website

      country {
        id
        translations(filter: { locales: ["en", "ru"] })
      }
    }
  }
`;
