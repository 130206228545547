import React, { useState } from 'react'
import * as yup from 'yup'
import { useMutation } from '../../mutation'
import { useQuery } from '../../query'
import { useHistory } from 'react-router-dom'
import { UpdateVisaRequirementM } from './mutations'
import { CountriesQ, VisaRequirementQ } from './queries'
import CountryInput from '../shared/inputs/country'
import TextInput from '../shared/inputs/text'
import { Button, Card, Spin, Alert, Select, Form, Input } from 'antd'
import { processErrors } from '../../utils'

type UpdateVisaRequirementInput = {
  duration?: number | null,
  requirement?: string,
  citizenCountryId?: number,
  destinationCountryId?: number
}

export const schema = yup.object().shape({
  duration: yup.number().positive().nullable(),
  requirement: yup.string().oneOf(['free', 'required']),
  citizenCountryId: yup.number().required(),
  destinationCountryId: yup.number().required()
})

export default (props) => {
  let history = useHistory()
  const id = parseInt(props.match.params.id)

  const [input, setInput] = useState<UpdateVisaRequirementInput>({})
  const [errors, setErrors] = useState<Array<string>>([])
  const { loading: countriesLoading, error: countriesError, data: countriesSelectorData } = useQuery(CountriesQ)
  const { loading: visaLoading, error: visaError, data: visaRequirementData } = useQuery(VisaRequirementQ, { variables: { id } })
  const [updateVisaRequirement, { data: mutationData }] = useMutation(UpdateVisaRequirementM, setErrors)

  if (countriesLoading || visaLoading) return (<Spin />)
  if (countriesError || visaError) return (<Alert type='error' message='Fetching error' />)

  const { visaRequirement: { duration, requirement, citizenCountry, destinationCountry } } = visaRequirementData

  const defaultInput = {
    duration: duration,
    requirement: requirement,
    citizenCountryId: citizenCountry.id,
    destinationCountryId: destinationCountry.id
  }

  if (Object.keys(input).length === 0) setInput(defaultInput)

  const performMutation = () =>
    schema.validate(input)
      .then(_ => updateVisaRequirement({ variables: { id, input } }),
        e => processErrors(e, setErrors))

  if (mutationData) history.push({ pathname: "/visa_requirements", state: { mutationData: mutationData } })

  return (
    <Card className='form'>
      {errors.map(e => <Alert type='error' message={e} />)}

      <CountryInput
        label="Citizen Country"
        value={input.citizenCountryId || -1}
        disabled={countriesLoading}
        onChange={citizenCountryId => setInput({ ...input, citizenCountryId })}
        countries={countriesSelectorData.countries}
      />
      <CountryInput
        label="Destination Country"
        value={input.destinationCountryId || -1}
        disabled={countriesLoading}
        onChange={destinationCountryId => setInput({ ...input, destinationCountryId })}
        countries={countriesSelectorData.countries}
      />

      <TextInput
        label='duration'
        value={input.duration || null}
        onChange={e => setInput({ ...input, duration: parseInt(e.target.value) || null })}
      />

      <Form.Item label='Requirement'>
        <Input.Group>
          <Select
            style={{ width: '150px' }}
            value={input.requirement || 'free'}
            defaultValue='free'
            onChange={requirement => setInput({ ...input, requirement })}
          >
            <Select.Option key={'free'} value={'free'}>Free</Select.Option>
            <Select.Option key={'required'} value={'required'}>Required</Select.Option>
          </Select>
        </Input.Group>
      </Form.Item>

      <Button
        style={{ float: 'left' }}
        onClick={() => setInput({})}
        children='Reset Changes'
      />

      <Button
        disabled={false}
        type='primary'
        htmlType='submit'
        style={{ float: 'right' }}
        children='Save'
        onClick={performMutation}
      />
    </Card>
  )
}
